import { createSvgIcon } from '@mui/material/utils';

const Permission = createSvgIcon(
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    viewBox='0 0 1000 1000'
    enable-background='new 0 0 1000 1000'
  >
    <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
    <g>
      <path d='M834.1,729.8c-8.5-7.5-21.2-7.1-28.3,1c-7.1,8.1-5.7,20.7,2.8,28.2c8.4,7.3,21.1,7,28.2-1.1C843.9,749.8,842.5,737.1,834.1,729.8z' />
      <path d='M772,625.3c-100.7,0-182.3,81.6-182.3,182.3S671.3,990,772,990c100.7,0,182.3-81.6,182.3-182.3C954.3,707,872.7,625.3,772,625.3z M859.7,805.1c-15.1,17.3-38.3,23.4-60.2,18.8l-82.8,94.8l-30.9,1c-4.8,0.2-10.6-5.2-11.1-9.5l-4-38.2l0.2-0.4l22.8-0.9l-2.3-22l21.9-0.8l-2.1-21.8l23.3-2.9l-1.5-23.8l16.4-18.8c-8.1-21.2-5.1-45.5,10.2-63c22.7-26,60.4-30.3,87.9-6.3C875,735.4,882.4,779,859.7,805.1z' />
      <path d='M682.5,552.1c-1.4-1.8-2.8-3.1-4.5-4.7c-64.3-63.7-150.2-103.9-245-107.4c115.3-9.2,205.8-101.8,205.8-214.6C638.8,106.4,538.2,10,414,10c-124.1,0-224.8,96.4-224.8,215.4c0,114.1,92.7,207.5,209.8,214.9C216.2,450.3,67.9,596.7,46.2,783.7c-0.2,1.2-0.3,2.4-0.4,3.6c-0.1,1-0.1,1.9-0.1,2.9c0,21.8,16.9,39.6,38.3,41c0.9,0.1,1.9,0.1,2.9,0.1c1,0,1.9,0,2.9-0.1c0,0,329.6,0.1,415.9,0.1c13.8-2.3,24.7-13.1,27.3-26.8c2.2-94.2,54.6-174.9,137.3-211.9c1.6-0.5,3.1-1.2,4.5-2c8-4.4,13.4-13,13.4-22.7C688.1,561.9,685.9,556.5,682.5,552.1z' />
    </g>
  </svg>,
  'Permission'
);

export default Permission;
