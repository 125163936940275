import { forwardRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// @mui
import { Box } from '@mui/material';
//
import getRatio from './getRatio';
import { ImageProps } from './types';
import MyAvatar from 'components/Avatar';

// ----------------------------------------------------------------------

const Image = forwardRef<HTMLSpanElement, ImageProps>(
  ({ ratio, disabledEffect = false, effect = 'blur', sx, name, ...other }, ref) => {
    let content = (
      // <Box
      //   component={() => (

      //   )}
      //   wrapperClassName='wrapper'
      //   effect={disabledEffect ? undefined : effect}
      //   placeholderSrc={disabledEffect ? '/assets/transparent.png' : '/assets/placeholder.svg'}
      //   sx={{ width: 1, height: 1, objectFit: 'cover' }}
      //   {...other}
      // />
      <LazyLoadImage
        wrapperClassName='wrapper'
        effect={disabledEffect ? undefined : effect}
        placeholderSrc={disabledEffect ? '/assets/transparent.png' : '/assets/placeholder.svg'}
        style={{ width: '100%', objectFit: 'cover', ...{ sx } }}
        {...other}
      />
    );
    if (other.src == '' || other.src == null) {
      content = (
        <MyAvatar
          name={name}
          isImage={!!other.src}
          url={other.src}
          sx={{ ...sx, bottom: 0 }}
          {...other}
        />
      );
    }

    if (ratio) {
      return (
        <Box
          ref={ref}
          component='span'
          sx={{
            // width: 1,
            // lineHeight: 1,
            display: 'block',
            overflow: 'hidden',
            position: 'relative',
            pt: getRatio(ratio),
            '& .wrapper': {
              top: 0,
              left: 0,
              width: 1,
              height: 1,
              position: 'absolute',
              backgroundSize: 'cover !important',
            },
            ...sx,
          }}
        >
          {content}
        </Box>
      );
    }

    return (
      <Box
        ref={ref}
        component='span'
        sx={{
          lineHeight: 1,
          display: 'block',
          overflow: 'hidden',
          position: 'relative',
          '& .wrapper': {
            width: 1,
            height: 1,
            backgroundSize: 'cover !important',
          },
          ...sx,
        }}
      >
        {content}
      </Box>
    );
  }
);

export default Image;
