import type { FC } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Hidden, IconButton, Toolbar } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import type { AppBarProps } from '@mui/material';
import MenuIcon from 'icons/Menu';
import AccountPopover from './AccountPopover';
// import ContentSearch from './ContentSearch';
import NotificationsPopover from './NotificationsPopover';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import SearchField from './SearchField';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
  }),
  zIndex: 100,
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { isCollapse } = useCollapseDrawer();

  const isDev = window?.location?.hostname.includes(
    process.env.REACT_APP_PREFIX_SUPER_DEV_ADMIN_DOMAIN as string
  );

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar
        sx={{
          minHeight: 64,
          backgroundColor: 'background.default',
          justifyContent: {
            lg: 'flex-end',
            xs: 'space-between',
          },
        }}
      >
        <Hidden lgUp>
          <IconButton color='inherit' onClick={onSidebarMobileOpen}>
            <MenuIcon fontSize='small' />
          </IconButton>
        </Hidden>
        <Box
          sx={{
            width: isCollapse ? '100%' : 'calc(100% - 280px)',
            display: 'flex',
            ...(isDev && {
              justifyContent: {
                xs: 'flex-end',
              },
            }),
            ...(!isDev && {
              justifyContent: {
                lg: 'space-between',
                xs: 'flex-end',
              },
            }),
          }}
        >
          <Box
            sx={{
              display: { lg: 'flex', xs: 'none' },
            }}
          >
            <SearchField />
          </Box>

          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: 3 }}
          >
            {/* <Box sx={{ ml: 1 }}>
              <ContentSearch />
            </Box> */}
            <Box>
              <NotificationsPopover />
            </Box>

            <Box>
              <AccountPopover />
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
