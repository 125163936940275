import { createSvgIcon } from '@mui/material/utils';

const Notifications = createSvgIcon(
 <svg xmlns="http://www.w3.org/2000/svg" id="bell" width="24.217" height="18.358" viewBox="0 0 24.217 18.358">
  <path id="Path_2422" data-name="Path 2422" d="M38.977,19.949a5.119,5.119,0,0,1,6.543-1.2,5.2,5.2,0,0,1,2.42,3.148l.2.73a6.173,6.173,0,0,0,2.876,3.748,1.072,1.072,0,0,1-.258,1.963l-8.9,2.385a5.081,5.081,0,0,1-.1-2.964l.2-.73a6.273,6.273,0,0,0-2.972-7.081Z" transform="translate(-27.332 -16.748)" fill="currentColor"/>
  <path id="Path_2423" data-name="Path 2423" d="M49.791,50.366a2.1,2.1,0,1,0,3.657-.98Z" transform="translate(-34.189 -36.679)" fill="currentColor"/>
  <path id="Path_2424" data-name="Path 2424" d="M45.376,15.817l-.245-.914a.606.606,0,0,0-1.171.314l.245.914a5.076,5.076,0,0,1,1.171-.314Z" transform="translate(-30.489 -14.454)" fill="currentColor"/>
  <path id="Path_2425" data-name="Path 2425" d="M19.706,55.386a2.1,2.1,0,1,0,3.657.98Z" transform="translate(-14.748 -40.497)" fill="currentColor"/>
  <path id="Path_2426" data-name="Path 2426" d="M33.782,22.131l.245-.914a.606.606,0,1,0-1.171-.314l-.245.914a5.071,5.071,0,0,1,1.137.3Z" transform="translate(-23.281 -18.271)" fill="currentColor"/>
  <path id="Path_2427" data-name="Path 2427" d="M11.92,35.455l3.657.98,4.164,1.116a1.072,1.072,0,0,0,1.205-1.571,6.173,6.173,0,0,1-.617-4.684l.2-.73a5.179,5.179,0,0,0-3.062-6.14l-.034-.014a5.071,5.071,0,0,0-1.137-.3A5.179,5.179,0,0,0,10.57,27.9l-.2.73A6.173,6.173,0,0,1,7.5,32.376a1.072,1.072,0,0,0,.258,1.963Z" transform="translate(-6.962 -20.565)" fill="currentColor"/>
</svg>,
 'Notification'
);

export default Notifications;
