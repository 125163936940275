import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'nprogress/nprogress.css';
import 'react-phone-input-2/lib/material.css';
import './index.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { StyledEngineProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import App from './App';
import { AuthProvider } from 'contexts/JWTContext';
import { SettingsProvider } from 'contexts/SettingsContext';
import { CollapseDrawerProvider } from 'contexts/CollapseDrawerContext';
import { SocketsProvider } from 'contexts/SocketContext';

import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <StyledEngineProvider injectFirst>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <BrowserRouter>
                  <SocketsProvider>
                    <AuthProvider>
                      {process.env.REACT_APP_QUERY_DEVTOOLS == 'true' && <ReactQueryDevtools />}
                      <App />
                    </AuthProvider>
                  </SocketsProvider>
                </BrowserRouter>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </StyledEngineProvider>
        </HelmetProvider>
      </QueryClientProvider>
      <div id='notification-snackbar' />
      <div id='error-handler-snackbar' />
    </LocalizationProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
