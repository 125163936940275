/* eslint-disable @typescript-eslint/no-unused-vars */
import * as deviceDetect from 'react-device-detect';

import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import axios from 'utils/axios';
import { curd } from 'api/curd';
import type { User } from 'types/user';
import { permission2match } from 'helpers/permissions-2-match';
import pureAxios from 'axios';
import { showSuccessSnackbarMessage, showErrorSnackbarMessage } from 'helpers/snackbar.helper';
import { useNavigate } from 'react-router-dom';
import { useBattery, useGeolocation, useNetworkState } from 'react-use';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  allowsRoutes: any[];
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
  Me: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type GetMeAction = {
  type: 'ME';
  payload: {
    user: User;
    allowsRoutes: any[];
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction | GetMeAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  allowsRoutes: [],
  user: null,
};

const setSession = (JWT: string | null): void => {
  if (JWT) {
    localStorage.setItem('JWT', JWT);
    axios.defaults.headers.common.Authorization = `Bearer ${JWT}`;
  } else {
    localStorage.removeItem('JWT');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state?: State | any, action?: Action | any) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  ME: (state: State, action: GetMeAction): State => {
    const { user, allowsRoutes } = action.payload;

    return {
      ...state,
      isInitialized: true,
      isAuthenticated: true,
      allowsRoutes,
      user,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  Me: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const isSuperAdmindevRoute = window?.location?.hostname.includes(
    process.env.REACT_APP_PREFIX_SUPER_DEV_ADMIN_DOMAIN as string
  );
  const isSuperAdminRoute = window?.location?.hostname.includes(
    process.env.REACT_APP_PREFIX_SUPER_ADMIN_DOMAIN as string
  );

  const getRouter = () => {
    if (isSuperAdmindevRoute) return `dev-dashboard`;
    if (isSuperAdminRoute) return `super-dashboard`;
    return `dashboard`;
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const navigate = useNavigate();
  const battery = useBattery() as any;
  const network = useNetworkState();
  const geolocation = useGeolocation();

  const getMe = async () => {
    try {
      if (!!localStorage.getItem('JWT')) {
        const response = await curd.getAll({ url: 'dashboard/profile/me' });
        dispatch({
          type: 'ME',
          payload: {
            user: response.data.data,
            allowsRoutes: permission2match(response.data.data.permission_keys),
          },
        });
      }
    } catch (e) {}
  };

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const JWT = window.localStorage.getItem('JWT');

        if (JWT) {
          setSession(JWT);

          await getMe();
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };
    initialize();
  }, []);

  const login = async (username: string, password: string): Promise<void> => {
    const resIp = await pureAxios.get(`https://ipapi.co/json/`);

    const res = await curd.addOne({
      url: `${getRouter()}/auth/login`,
      params: {
        username,
        password,
        device_details: {
          device_id: '',
          device_name: deviceDetect.engineName,
          device_model: deviceDetect.mobileModel,
          device_type: deviceDetect.deviceType,
          os_version: deviceDetect.osVersion,
          full_browser_version: deviceDetect.fullBrowserVersion,
          app_version: '1',
          ip_address: resIp?.data?.ip,
          region: '',
          time_zone: '',
          mobile_carrier: '',
          screen_resolution: `${window?.screen?.availHeight}x${window?.screen?.availWidth}`,
          battery_level: battery?.level || '',
          battery_state: battery?.charging || '',
          network_type: network?.effectiveType || '',
          mobile_country_code: '',
          mobile_network_code: '',
          vpn_connected: true,
        },
      },
    });
    setSession(res.data.data.token);
    if (res.data.data.admin) {
      dispatch({
        type: 'LOGIN',
        payload: {
          user: res.data.data.admin as any,
        },
      });
      await getMe();

      showSuccessSnackbarMessage('login done');
      navigate('/dashboard', { replace: true });
    } else {
      showErrorSnackbarMessage('not authorized');
    }
  };

  const logout = async (): Promise<void> => {
    // setSession(null);
    await curd.addOne({
      url: `${getRouter()}/auth/logout`,
    });
    await dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, name: string, password: string): Promise<void> => {
    const response = await axios.post<{ JWT: string; user: User }>('/api/authentication/register', {
      email,
      name,
      password,
    });
    const { JWT, user } = response.data;

    window.localStorage.setItem('JWT', JWT);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        Me: getMe,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
