/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { curd } from 'api/curd';

export const useMutationMarkNotificationsAsRead = (page: number, limit: number) => {
  const queryClient = useQueryClient();

  const Mutation = useMutation(
    //@ts-ignore
    (params) => {
      return curd.edit({ url: `dashboard/profile/notification/mark-read`, params: {} });
    },
    {
      onSuccess: (data) => {
        for (let i = 0; i <= page; i++) {
          const currentPage = i;

          queryClient.setQueryData(
            [`dashboard/profile/notification?page=${currentPage}&limit=${limit}`],
            (old: any) => {
              old.data.data.notifications = old.data.data.notifications.map((n: any) => {
                n.is_read = true;
                return n;
              });
              old.data.data.unread_notifications_count = 0;
              return old;
            }
          );
        }
      },
    }
  );
  return Mutation;
};
