export const SERVER_BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://testing-api.cms.vidnik.com/api';
export const REACT_APP_CHAT_SOCKET_URL =
  process.env.REACT_APP_CHAT_SOCKET_URL || 'https://ct.cm.sr-testing.vidnik.com/chat';

// Auth
export const LOGIN_API = `${SERVER_BASE_URL}/dashboard/auth/login`;
export const LOGOUT_API = `${SERVER_BASE_URL}/dashboard/auth/logout`;

// Profile
export const ADD_UPDATE_FCM_TOKEN_API = `${SERVER_BASE_URL}/dashboard/profile/fcm-token`;
