/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { curd } from 'api/curd';

// const query = useQuery(
//   `dashboard/statistics/users-matrix?month=${month}`,
//   () => curd.getAll({ url: `dashboard/statistics/users-matrix`, params: { month } }),
//   {
//     initialData: [] as any
//   }
// );

export const useMutationMarkNotificationAsRead = (
  id: string | undefined,
  page: number,
  limit: number
) => {
  const queryClient = useQueryClient();

  const Mutation = useMutation(
    //@ts-ignore
    (params) => {
      return curd.edit({ url: `dashboard/profile/notification/mark-read/${id}`, params: { id } });
    },
    {
      onSuccess: (data) => {
        for (let i = 0; i <= page; i++) {
          const currentPage = i;

          queryClient.setQueryData(
            [`dashboard/profile/notification?page=${currentPage}&limit=${limit}`],
            (old: any) => {
              old.data.data.notifications = old.data.data.notifications.map((n: any) => {
                if (n.id == id) {
                  n.is_read = true;
                }
                return n;
              });
              if (old.data.data.unread_notifications_count != 0) {
                old.data.data.unread_notifications_count =
                  old.data.data.unread_notifications_count - 1;
              }

              return old;
            }
          );
        }
      },
    }
  );
  return Mutation;
};
