import { createCipheriv, createDecipheriv } from 'crypto';

const MAIN_CIPHER_SECRET_KEY = process.env.REACT_APP_MAIN_CIPHER_SECRET_KEY || 'YOUR SECRET KEY';

export const encryptMessage = (message: string) => {
  const userKeySliced = MAIN_CIPHER_SECRET_KEY;
  const passwordHash = Buffer.from(userKeySliced, 'base64');
  const iv2 = Buffer.from('mBj0tzBUxDFmix1T', 'base64');
  const cipher = createCipheriv('aes-256-gcm', passwordHash, iv2);

  const encryptedData = Buffer.concat([cipher.update(message, 'utf8'), cipher.final()]);
  return Buffer.concat([iv2, encryptedData, cipher.getAuthTag()]).toString('base64');
};

export const decryptMessage = (message: string | undefined) => {
  if (message == undefined) return '';
  const userKeySliced = MAIN_CIPHER_SECRET_KEY;
  const passwordHash = Buffer.from(userKeySliced, 'base64');

  const combinerBuffer = Buffer.from(message, 'base64');
  const iv2 = combinerBuffer.slice(0, 12);
  const deciper = createDecipheriv('aes-256-gcm', passwordHash, iv2);
  const temp = combinerBuffer.length - 16;
  deciper.setAuthTag(combinerBuffer.slice(temp));
  const result = deciper.update(combinerBuffer.slice(12, temp)) + deciper.final('utf8');
  return result;
};
