import { createSvgIcon } from '@mui/material/utils';

const Email = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='email'
    width='17.699'
    height='20.115'
    viewBox='0 0 17.699 20.115'
  >
    <path
      id='Path_2428'
      data-name='Path 2428'
      d='M29.7,18.9a.787.787,0,0,0-.514.19l-7.2,6.175a1.738,1.738,0,0,1-2.263,0l-7.2-6.175A.787.787,0,0,0,12,18.9V17.685a1.264,1.264,0,0,1,.441-.96l6.763-5.8a2.528,2.528,0,0,1,3.291,0l6.763,5.8a1.264,1.264,0,0,1,.441.96Z'
      transform='translate(-12 -10.32)'
      fill='currentColor'
    />
    <path
      id='Path_2429'
      data-name='Path 2429'
      d='M12,43.256V51.7a1.264,1.264,0,0,0,1.264,1.264H28.435A1.264,1.264,0,0,0,29.7,51.7V43.256l-6.69,5.734a3.319,3.319,0,0,1-4.319,0Z'
      transform='translate(-12 -32.847)'
      fill='currentColor'
    />
  </svg>,
  'Email'
);

export default Email;
