/* eslint-disable @typescript-eslint/no-unused-vars */
import axiosInstance, { baseUrl } from 'utils/axios';
import { showSuccessSnackbarMessage } from 'helpers/snackbar.helper';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import useAuth from 'hooks/useAuth';
import { removeJWT } from 'helpers/local-storage.helper';

export const curd = {
  getAll: async ({ url, params }: { url?: string; params?: any }) =>
    axiosInstance.get(`${baseUrl}/${url}`, { params }),
  getOne: async ({ url, params, id }: { url?: string; params?: any; id?: string }) =>
    axiosInstance.get(`${baseUrl}/${url}/${id}`, { ...params }),
  deleteOne: async ({ url, params, id }: { url?: string; params?: any; id?: string }) =>
    axiosInstance.delete(`${baseUrl}/${url}/${id}`, { ...params }),
  delete: async ({ url, params, id }: { url?: string; params?: any; id?: string }) =>
    axiosInstance.delete(`${baseUrl}/${url}`, { ...params }),
  addOne: async ({ url, params }: { url?: string; params?: any }) =>
    axiosInstance.post(`${baseUrl}/${url}`, { ...params }),
  editOne: async ({ url, params, id }: { url?: string; params?: any; id?: string }) =>
    axiosInstance.put(`${baseUrl}/${url}/${id}`, { ...params }),
  edit: async ({ url, params }: { url?: string; params?: any }) =>
    axiosInstance.put(`${baseUrl}/${url}`, { ...params }),
};

interface Props {
  id: string;
  method: 'addOne' | 'deleteOne' | 'edit' | 'editOne' | 'getOne' | 'getAll';
  queryKey: any;
  dataKey: string;
  url: string;
  toastMessage: string;
  gotoAterOnSuccess?: string;
}

export const useMutationOnArray = ({
  id,
  method,
  url,
  dataKey,
  queryKey,
  toastMessage,
  gotoAterOnSuccess,
}: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const auth = useAuth();

  const Mutation = useMutation(
    //@ts-ignore
    (params) => curd[method]({ url, id, params: { ...params } }),
    {
      onSuccess: (data: any) => {
        if (method === 'addOne') {
          const newData = _.get(data, dataKey, undefined);

          queryClient.setQueryData(queryKey, (old: any) => {
            showSuccessSnackbarMessage(toastMessage);
            if (old === undefined) navigate(gotoAterOnSuccess as string, { replace: true });
            old.data.data.push(newData);
            return old;
          });
        }
        if (method === 'editOne') {
          const newData = _.get(data, dataKey, undefined);
          queryClient.setQueryData(queryKey, (old: any) => {
            if (old === undefined) navigate(gotoAterOnSuccess as string);
            const res = old.data.data.map((item: any) => {
              showSuccessSnackbarMessage(toastMessage);
              if (item.id === id) return newData;
              return item;
            });
            old.data.data = res;

            return old;
          });
        }
        if (method === 'edit') {
          showSuccessSnackbarMessage(toastMessage);
          console.log('datadata:\n', data);
          console.log('auth:\n', auth?.user);

          if (
            auth?.user?.username != data?.data?.data?.username ||
            auth?.user?.email != data?.data?.data?.email
          ) {
            removeJWT();
            window.location.href = '/login';
          }

          return data;
        }
        if (method === 'deleteOne') {
          queryClient.setQueryData(queryKey, (old: any) => {
            const res = old.data.data.filter((item: any) => item.id !== id);
            old.data.data = res;
            showSuccessSnackbarMessage(toastMessage);

            return old;
          });
        }
      },
      onError: (error) => {
        console.log({ error });
      },
    }
  );
  return Mutation;
};
