import Trending from 'icons/Trending';
import Dashboard from 'icons/Dashboard';
import CSSIcon from 'icons/CSSIcon';
import Admin from 'icons/Admin';
import Subscription from 'icons/Subscription';
// import Flag from 'icons/Flag';
import Messages from 'icons/Message';
import Email1 from 'icons/Email1';
import Email2 from 'icons/Email2';
import UsersIcon from 'icons/Users';
import Notifications from 'icons/Notification';
import DailyReport from 'icons/DailyReport';
import Performance from 'icons/Performance';
import Male from 'icons/Male';
import Female from 'icons/Female';
import Search from 'icons/Search';
import UserDelete from 'icons/UserDelete';
import UserMatch from 'icons/Match';
import Coins from 'icons/Coins';
import Dependents from 'icons/Dependents';
import Recommend from 'icons/Recommend';
import Role from 'icons/super-dashboard/Role';
import SuperAdmin from 'icons/super-dashboard/Admin';
import Logs from 'icons/super-dashboard/Logs';
import Checked from 'icons/super-dashboard/Checked';
import TechAdmin from 'icons/super-dashboard/TechAdmin';
import Payments from 'icons/Payments';
import HeadPhones from 'icons/HeadPhones';
import Selfie from 'icons/Selfie';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { Skeleton } from '@mui/material';
import { TicketReplyStatuses } from 'dto/ticket';

export const sections = [
  {
    title: 'OVERVIEW',
    items: [
      {
        title: 'dashboard',
        path: '/dashboard',
        icon: <Dashboard fontSize='small' />,
      },
      {
        title: 'Trending',
        path: '/dashboard/trending',
        icon: <Trending fontSize='small' />,
      },
      {
        title: 'User Reports',
        path: '/dashboard/user-reports',
        icon: <DailyReport fontSize='small' />,
        children: [
          {
            title: 'Search',
            path: '/dashboard/search-users',
            icon: <Search fontSize='small' />,
          },
        ],
      },
      {
        title: 'Deletion Request',
        path: '/dashboard/delete-users',
        icon: <UserDelete fontSize='small' />,
      },
      {
        title: 'Match users',
        path: '/dashboard/match-users',
        icon: <UserMatch fontSize='small' />,
        children: [
          {
            title: 'Dashboard',
            path: '/dashboard/match-users/dashboard',
          },
          {
            title: 'List',
            path: '/dashboard/match-users/list',
          },
          {
            title: 'Summary',
            path: '/dashboard/match-users/summary',
          },
        ],
      },
      {
        title: 'Recommendations',
        path: '/dashboard/recommended-user',
        icon: <Recommend fontSize='small' />,
      },
      {
        title: 'Dependents',
        path: '/dashboard/dependents',
        icon: <Dependents fontSize='small' />,
      },
      {
        title: 'Selfie',
        path: '/dashboard/selfie',
        icon: <Selfie fontSize='small' />,
        children: [
          {
            title: 'All',
            path: '/dashboard/selfie/all',
          },
          {
            title: 'Verified',
            path: '/dashboard/selfie/verified',
          },
          {
            title: 'Rejected',
            path: '/dashboard/selfie/rejected',
          },
          {
            title: 'Waiting',
            path: '/dashboard/selfie/waiting',
          },
        ],
      },
      {
        title: 'Check Users',
        path: '/dashboard/check-user',
        icon: <Checked fontSize='small' />,
        children: [
          {
            title: 'Males',
            path: '/dashboard/male',
            icon: <Male fontSize='small' />,
          },
          {
            title: 'Females',
            path: '/dashboard/female',
            icon: <Female fontSize='small' />,
          },
          {
            title: 'Walis',
            path: '/dashboard/walis',
            icon: <UsersIcon fontSize='small' />,
          },
        ],
      },
      // {
      //   title: 'All Flagged Photos',
      //   path: '/dashboard/all-flagged-photo',
      //   icon: <Flag fontSize='small' />,
      // },
      {
        title: 'All Users Reports',
        path: `/dashboard/all-reported-users`,
        icon: <DailyReport fontSize='small' />,
      },
      {
        title: 'Subscriptions',
        path: '/dashboard/subscription',
        icon: <Subscription fontSize='small' />,
        children: [
          {
            title: 'Dashboard',
            path: '/dashboard/subscription/dashboard',
          },
          {
            title: 'List',
            path: '/dashboard/subscription/list',
          },
          {
            title: 'Summary',
            path: '/dashboard/subscription/summary',
          },
        ],
      },
      {
        title: 'Wali Payments',
        path: '/dashboard/wali-payments',
        icon: <Payments fontSize='small' />,
      },
      {
        title: 'User Coins',
        path: '/dashboard/user-coins',
        icon: <Coins fontSize='small' />,
      },
      {
        title: 'Chats',
        path: '/dashboard/messages',
        icon: <Messages fontSize='small' />,
      },
      {
        title: 'Support System',
        path: '/dashboard/support-system',
        icon: <HeadPhones fontSize='small' />,
        children: [
          {
            title: 'Dashboard',
            path: '/dashboard/support-system',
          },
          {
            title: 'All Tickets',
            path: '/dashboard/support-system/all-tickets',
          },
          {
            title: 'My Pending Reply Tickets',
            path: `/dashboard/support-system/my-tickets/${TicketReplyStatuses.WAITING_REPLY}`,
            rightData: <Skeleton width={25} height={25} variant='circular' />,
          },
          {
            title: 'My New Tickets',
            path: '/dashboard/support-system/my-tickets/open',
            rightData: <Skeleton width={25} height={25} variant='circular' />,
          },
          {
            title: 'My In Progress Tickets',
            path: '/dashboard/support-system/my-tickets/in_progress',
            rightData: <Skeleton width={25} height={25} variant='circular' />,
          },
          {
            title: 'My Closed Tickets',
            path: '/dashboard/support-system/my-tickets/closed',
            rightData: <Skeleton width={25} height={25} variant='circular' />,
          },
          {
            title: 'My All Tickets',
            path: '/dashboard/support-system/my-tickets/all',
            rightData: <Skeleton width={25} height={25} variant='circular' />,
          },
        ],
      },
    ],
  },
  {
    title: 'ALL SETTINGS',
    items: [
      {
        title: 'App Notifications',
        path: '/dashboard/app-notifications',
        icon: <Notifications fontSize='small' />,
      },
      {
        title: 'Send Email',
        path: '/dashboard/send-email',
        icon: <Email1 fontSize='small' />,
      },
      {
        title: 'App Settings',
        icon: <Admin fontSize='small' />,
        path: '/dashboard/app-settings',
      },
      {
        title: 'Email Templates',
        path: '/dashboard/email-templated',
        icon: <Email2 fontSize='small' />,
      },
    ],
  },
];

export const superAdminSections = [
  {
    title: 'OVERVIEW',
    items: [
      {
        title: 'dashboard',
        path: '/dashboard',
        icon: <Dashboard fontSize='small' />,
      },
      {
        title: 'All logs',
        path: '/dashboard/admins-all-logs',
        icon: <Logs fontSize='small' />,
      },
      {
        icon: <SuperAdmin fontSize='small' />,
        title: 'Super Admins',
        path: '/dashboard/super-admin',
      },
      {
        icon: <TechAdmin fontSize='small' />,
        title: 'Tech support admin',
        path: '/dashboard/tech-admin',
      },
      {
        icon: <UsersIcon fontSize='small' />,
        title: 'Sub Admins',
        path: '/dashboard/sub-admin',
      },

      {
        title: 'Report',
        path: '/dashboard/all-admins',
        icon: <DailyReport fontSize='small' />,
      },
      {
        title: 'Team Performance Report',
        path: '/dashboard/performance-report',
        icon: <Performance fontSize='small' />,
      },
      {
        title: 'Team Status Report',
        path: '/dashboard/system-status-report',
        icon: <TroubleshootIcon fontSize='small' />,
      },
      {
        title: 'System Status Report',
        path: '/dashboard/cs-performance-report',
        icon: <HeadPhones fontSize='small' />,
      },
      {
        title: 'Role',
        path: '/dashboard/role-management',
        icon: <Role fontSize='small' />,
      },
      {
        title: 'Trending',
        path: '/dashboard/trending',
        icon: <Trending fontSize='small' />,
      },
    ],
  },
];

export const devAdminSections = [
  {
    title: 'Dev Admin',
    items: [
      {
        title: 'style file',
        path: '/dashboard/email-template-dev/style-file',
        icon: <CSSIcon fontSize='small' />,
        children: [] as any,
      },
      {
        title: 'Email Template',
        path: '/dashboard/email-template-dev',
        icon: <CSSIcon fontSize='small' />,
        children: [],
      },
    ],
  },
];
