import { getMyTimezone } from './moment.helper';
import { formatInTimeZone } from 'date-fns-tz';
export const makeTimeFormatDateFNSHelper = (
  date: string | Date | null | undefined,
  format: string = 'yyyy-MM-dd hh:mm:ss a'
) => {
  if (date == undefined || date == null || date == '') return '';
  const timeZone = getMyTimezone();
  const time = formatInTimeZone(new Date(date), timeZone, format);
  return time;
};
