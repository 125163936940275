import { Box } from '@mui/material';
import IconButton from 'components/IconButton';

const icon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='11.449'
    height='12.518'
    viewBox='0 0 11.449 12.518'
  >
    <g id='arrow' transform='translate(0.5 0.707)'>
      <path
        id='Path_2448'
        data-name='Path 2448'
        d='M0,5.121,4.816.3A1.041,1.041,0,0,1,6.288.3L11.1,5.121'
        transform='translate(5.121 11.104) rotate(-90)'
        fill='none'
        stroke='#c2ccde'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1'
        opacity='0.264'
      />
      <path
        id='Path_2449'
        data-name='Path 2449'
        d='M0,5.121,4.816.3A1.041,1.041,0,0,1,6.288.3L11.1,5.121'
        transform='translate(0 11.104) rotate(-90)'
        fill='none'
        stroke='#6c6c6d'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1'
      />
    </g>
  </svg>
);

type Props = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

export default function CollapseButton({ onToggleCollapse, collapseClick }: Props) {
  return (
    <IconButton onClick={onToggleCollapse}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(collapseClick && {
            transform: 'rotate(180deg)',
          }),
        }}
      >
        {icon}
      </Box>
    </IconButton>
  );
}
