import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import { showNotification } from 'helpers/snackbar.helper';
import { NotificationTypes } from './notification-type';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseMessaging = getMessaging(firebaseApp);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(firebaseMessaging, (payload) => {
      // don't show a ticket reply notification if user open same ticket details
      if (payload?.data?.type == NotificationTypes.TICKET_REPLY) {
        const currentPath = window.location.pathname;
        if (
          !currentPath.startsWith('/dashboard/support-system/ticket/') &&
          !currentPath.includes(payload?.data?.ticket_id)
        ) {
          showNotification(payload?.notification?.title, payload?.notification?.body);
        }
      } else {
        showNotification(payload?.notification?.title, payload?.notification?.body);
      }
      resolve(payload);
    });
  });
