import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, Collapse, ListItem } from '@mui/material';
import type { ListItemProps } from '@mui/material';
import ChevronDownIcon from 'icons/ChevronDown';
import ChevronRightIcon from 'icons/ChevronRight';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import useAuth from 'hooks/useAuth';

interface NavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
  rightData?: ReactNode;
}

const NavItem: FC<NavItemProps> = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    rightData,
    ...other
  } = props;
  const [open, setOpen] = useState<any>(openProp);
  const auth = useAuth();
  const { isCollapse } = useCollapseDrawer();

  const handleToggle = (): void => {
    setOpen((prevOpen: any) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children && !isCollapse) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0,
        }}
        {...other}
      >
        <Button
          endIcon={
            !open ? <ChevronRightIcon fontSize='small' /> : <ChevronDownIcon fontSize='small' />
          }
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: 'text.secondary',
            fontWeight: 400,
            justifyContent: 'flex-start',
            gap: '12px',
            pl: `${paddingLeft}px`,
            pr: '8px',
            height: '50px',
            my: '2px',
            textAlign: 'start',
            textTransform: 'none',
            width: '100%',
            borderRadius: '100px',
          }}
          variant='text'
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }
  if (isCollapse)
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0,
        }}
        {...other}
      >
        <IconButton disabled color='primary'>
          {icon}
        </IconButton>
      </ListItem>
    );
  // Leaf
  if (
    auth.allowsRoutes.some((itemAuth: any) => path == itemAuth) ||
    auth.user?.is_super_admin ||
    auth.user?.is_super_developer
  )
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'flex',
          py: 0,
        }}
      >
        <Button
          //@ts-ignore
          component={path && RouterLink}
          startIcon={icon}
          sx={{
            color: 'text.secondary',
            fontWeight: 400,
            justifyContent: 'flex-start',
            gap: '12px',
            textAlign: 'left',
            pl: `${paddingLeft}px`,
            pr: '8px',
            height: '50px',
            my: '2px',
            textTransform: 'none',
            width: '100%',
            borderRadius: '100px',
            ':hover': {
              color: (theme) => theme.palette.text.primary,
            },
            ...(active && {
              color: 'primary.main',
              background: '#292C2D',
              ':hover': {
                background: '#292C2D',
                color: (theme) => theme.palette.text.primary,
              },
              '& svg': {
                color: 'primary.main',
              },
            }),
          }}
          variant='text'
          to={path}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
          {rightData}
        </Button>
      </ListItem>
    );
  return <></>;
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  rightData: PropTypes.node,
};

NavItem.defaultProps = {
  active: false,
  open: false,
};

export default NavItem;
