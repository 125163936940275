import type { FC } from 'react';
import { Avatar, SxProps, Theme } from '@mui/material';

const stringToColor = (string: string) => {
  let hash = 0,
    color = '#',
    i;

  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

const stringAvatar = (name: string) => {
  let sAvatar = null;
  if (name != null && name[0] != null) {
    sAvatar = `${name[0][0]}`;
  }
  return {
    children: sAvatar,
  };
};

interface Props {
  isImage: Boolean;
  name: string;
  url?: string;
  sx?: SxProps<Theme>;
}

const MyAvatar: FC<Props> = ({ isImage, name, url, sx }) => {
  if (isImage)
    return (
      <Avatar
        alt={name}
        src={url}
        sx={{
          width: '34px',
          height: '34px',
          borderRadius: '50%',
          ...sx,
        }}
      />
    );
  else
    return (
      <Avatar
        {...stringAvatar(name)}
        sx={{
          bgcolor: stringToColor(name),
          width: '31px',
          height: '31px',
          borderRadius: '50%',

          ...sx,
        }}
      />
    );
};

export default MyAvatar;
