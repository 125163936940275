import { FC, SetStateAction, useState } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  Autocomplete,
  CircularProgress,
  Chip,
  Grid,
  ListItemButton,
} from '@mui/material';
import SearchIcon from 'icons/Search';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import { useQuery } from 'react-query';
import { curd } from 'api/curd';
import { useNavigate } from 'react-router-dom';
import useDebounce from 'hooks/useDebounce';
import useAuth from 'hooks/useAuth';

enum SuggestionModelKeys {
  SUPER_ADMIN = 'super-admin',
  SUB_ADMIN = 'sub-admin',
  APP_NOTIFICATION = 'app-notification',
  APP_EMAIL = 'app-email',
  USER = 'user',
  WALI = 'wali',
  Ticket = 'ticket',
}

interface Suggestion {
  value: string;
  module_key: string;
  similarity: number;
  column: string;
}

const SearchFied: FC = () => {
  const { isCollapse } = useCollapseDrawer();
  const auth = useAuth();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  //@ts-ignore
  const valueDebounce: string = useDebounce(searchText) as string;

  const query = useQuery(
    `dashboard/search?text=${valueDebounce}`,
    () =>
      curd.getAll({
        url: auth.user?.is_super_admin ? `super-dashboard/search` : `dashboard/search`,
        params: { text: valueDebounce },
      }),
    {
      enabled: auth.isInitialized && !!valueDebounce,
    }
  );

  const loading = query.isLoading;

  const handleSearchTextChange = (event: { target: { value: SetStateAction<string> } }) => {
    setOpen(true);
    setSearchText(event.target.value);
  };

  const handleClickOption = (option: Suggestion) => {
    setOpen(false);
    const text = option.value;
    let url = '',
      column = option.column;
    switch (option.module_key) {
      case SuggestionModelKeys.SUPER_ADMIN: {
        url += '/dashboard/super-admin';
        column = 'text';
        break;
      }
      case SuggestionModelKeys.SUB_ADMIN: {
        url += '/dashboard/sub-admin';
        column = 'text';
        break;
      }
      case SuggestionModelKeys.APP_EMAIL: {
        url += '/dashboard/send-email';
        column = 'text';
        break;
      }
      case SuggestionModelKeys.APP_NOTIFICATION: {
        url += '/dashboard/app-notifications';
        column = 'text';
        break;
      }
      case SuggestionModelKeys.USER: {
        url += '/dashboard/search-users';
        break;
      }
      case SuggestionModelKeys.WALI: {
        url += '/dashboard/search-users';

        break;
      }
      case SuggestionModelKeys.Ticket: {
        url += '/dashboard/customer-service';
        column = 'text';
        break;
      }

      default:
        break;
    }

    navigate(`${url}?${column}=${text}`, { replace: true });
    navigate(0);
  };

  const suggestions: Suggestion[] =
    query.data?.data?.data?.suggestions == null ? [] : query.data?.data?.data?.suggestions;

  const isDevDashboard = window?.location?.hostname.includes(
    process.env.REACT_APP_PREFIX_SUPER_DEV_ADMIN_DOMAIN as string
  );

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        transition: (theme) =>
          theme.transitions.create('padding-left', {
            duration: theme.transitions.duration.standard,
          }),
        ...(isCollapse && {
          paddingLeft: '50px',
        }),
        ...(!isCollapse && {
          paddingLeft: '0',
        }),
      }}
    >
      {isDevDashboard ? (
        <></>
      ) : (
        <Autocomplete
          freeSolo
          disableClearable
          id='search-input'
          open={open}
          onClick={() => {
            setOpen(true);
          }}
          options={suggestions}
          //@ts-ignore
          getOptionLabel={(option) => {
            return (option as Suggestion)?.value !== undefined
              ? (option as Suggestion)?.value
              : option;
          }}
          renderOption={(props, option) => (
            <ListItemButton
              onClick={() => {
                handleClickOption(option);
              }}
            >
              <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                spacing={2}
              >
                <Grid item xs={1}></Grid>
                <Grid item xs={8}>
                  {option.value}
                </Grid>
                <Grid item xs={3}>
                  <Chip label={option.module_key} variant='outlined' />
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </ListItemButton>
          )}
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: '99999px',
              width: '494px',
              // height: '46px',
              pl: '38%',
              background: '#161819',
            },
          }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              onChange={handleSearchTextChange}
              placeholder='Search ... ...'
              InputLabelProps={{
                sx: {
                  '& .MuiInputBase-root .Mui-focused': {
                    transform: 'translate(32px, 24px) scale(1)',
                  },
                },
              }}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon fontSize='small' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    {loading && !!valueDebounce && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    </Box>
  );
};

export default SearchFied;
