import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from 'translations/en';
import arTranslation from 'translations/ar';

const resources = {
  en: { translation: enTranslation },
  ar: { translation: arTranslation },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    load: 'currentOnly',
    fallbackLng: (code) => {
      if (code && (code === 'ar' || code.startsWith('ar'))) return 'ar';
      return 'en';
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
