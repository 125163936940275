/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-use-before-define */
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';
import { List, ListSubheader } from '@mui/material';
import type { ListProps } from '@mui/material';
import NavItem from './NavItem';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import useAuth from 'hooks/useAuth';
interface Item {
  path?: string;
  icon?: ReactNode;
  info?: ReactNode;
  children?: Item[];
  title: string;
  rightData?: ReactNode;
}

interface NavSectionProps extends ListProps {
  items: Item[];
  pathname: string;
  title: string;
}

const renderNavItems = ({
  depth = 0,
  items,
  pathname,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}): JSX.Element => {
  return (
    <List disablePadding>
      {/* @ts-ignore */}
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}
    </List>
  );
};

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: JSX.Element[];
  pathname: string;
  item: Item;
  depth: number;
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
      {
        path: item.path,
        end: true,
      },
      pathname
    )
    : false;

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
        {
          path: item.path,
          end: false,
        },
        pathname
      )
      : false;

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
        rightData={item.rightData}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        rightData={item.rightData}
      />
    );
  }

  return acc;
};

const NavSection: FC<NavSectionProps> = (props) => {
  const { items, pathname, title, ...other } = props;
  const [filterItems, setFilter] = useState<any>([]);
  const auth = useAuth();

  useEffect(() => {
    if (auth.allowsRoutes.length) {
      const res = items.filter((item) => {
        if (
          auth.allowsRoutes.some(
            (itemAuth: any) =>
              !!matchPath(
                {
                  path: itemAuth,
                },
                item.path as any
              )
          )
        )
          return true;
        return false;
      });
      setFilter(res);
    }
  }, [auth.allowsRoutes]);

  const { isCollapse } = useCollapseDrawer();


  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'text.primary',
            fontSize: '1rem',
            lineHeight: 2.5,
            fontWeight: 300,
            textTransform: 'uppercase',
          }}
        >
          {isCollapse === false && filterItems.length !== 0 ? title : undefined}
        </ListSubheader>
      }
      {...other}
    >
      {renderNavItems({
        items:
          (window?.location?.hostname.includes(
            process.env.REACT_APP_PREFIX_SUPER_DEV_ADMIN_DOMAIN as string
          ) || window?.location?.hostname.includes(
            process.env.REACT_APP_PREFIX_SUPER_ADMIN_DOMAIN as string
          ))
            ? items
            : filterItems,
        pathname,
      })}
    </List>
  );
};

export default NavSection;
