import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getJWT } from 'helpers/local-storage.helper';

interface RedirectIfAuthGuardProps {
  children: ReactNode;
}

const RedirectIfAuthGuard: FC<RedirectIfAuthGuardProps> = (props) => {
  const { children } = props;

  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  const JWT = getJWT();

  if (JWT != null) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to={`/dashboard`} replace={true} />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

RedirectIfAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default RedirectIfAuthGuard;
