import { createSvgIcon } from '@mui/material/utils';

const Users = createSvgIcon(
<svg xmlns="http://www.w3.org/2000/svg" id="user" width="21.893" height="15.986" viewBox="0 0 21.893 15.986">
  <path id="Path_2413" data-name="Path 2413" d="M16.935,33.669a2.085,2.085,0,0,1-4.08,0l-.044-.211a2.254,2.254,0,0,1,.389-1.8l.072-.1a2.014,2.014,0,0,1,3.247,0l.072.1a2.254,2.254,0,0,1,.389,1.8Z" transform="translate(-11.134 -26.13)" fill="currentColor"/>
  <path id="Path_2414" data-name="Path 2414" d="M8,50.89a.638.638,0,0,0,.638.638h2.984a4.616,4.616,0,0,1,1.814-3.147A6.632,6.632,0,0,0,9.4,48.6,2.17,2.17,0,0,0,8,50.63Z" transform="translate(-8 -37.594)" fill="currentColor"/>
  <path id="Path_2415" data-name="Path 2415" d="M60.9,51.529H57.915A4.616,4.616,0,0,0,56.1,48.382a6.632,6.632,0,0,1,4.04.221,2.17,2.17,0,0,1,1.4,2.028v.26A.638.638,0,0,1,60.9,51.529Z" transform="translate(-39.644 -37.594)" fill="currentColor"/>
  <path id="Path_2416" data-name="Path 2416" d="M54.855,33.669a2.085,2.085,0,0,0,4.08,0l.044-.211a2.254,2.254,0,0,0-.389-1.8l-.072-.1a2.014,2.014,0,0,0-3.247,0l-.072.1a2.254,2.254,0,0,0-.389,1.8Z" transform="translate(-38.763 -26.13)" fill="currentColor"/>
  <path id="Path_2417" data-name="Path 2417" d="M32.908,52.9H22.1A1.1,1.1,0,0,1,21,51.806v-.413a3.76,3.76,0,0,1,2.43-3.516,11.5,11.5,0,0,1,8.144,0A3.76,3.76,0,0,1,34,51.393v.413A1.1,1.1,0,0,1,32.908,52.9Z" transform="translate(-16.555 -36.914)" fill="currentColor"/>
  <path id="Path_2418" data-name="Path 2418" d="M31.253,24.725a3.606,3.606,0,0,0,5.189-2.453l.07-.33a3.871,3.871,0,0,0-.675-3.1l-.113-.153a3.495,3.495,0,0,0-5.619,0l-.113.153a3.871,3.871,0,0,0-.675,3.1l.07.33A3.606,3.606,0,0,0,31.253,24.725Z" transform="translate(-21.967 -17.268)" fill="currentColor"/>
</svg>,
'Users'
);

export default Users;
