import type { FC } from 'react';
import { useState, useCallback } from 'react';
import { alpha, Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { curd } from 'api/curd';
import { showSuccessSnackbarMessage } from 'helpers/snackbar.helper';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { permission } from 'config/permission';
interface Props {
  refetch: () => void;
  disabled: boolean;
}

const useMutationRelpy = () => {
  const paramsRouter = useParams();

  const Mutation = useMutation(
    (params: any) =>
      curd.addOne({
        url: `dashboard/ticket/${paramsRouter.id}/message`,
        params: { content: params.content },
      }),
    {
      onSuccess: () => {
        showSuccessSnackbarMessage('Update Done');
      },
    }
  );
  return Mutation;
};

export const MessageBox: FC<Props> = ({ refetch, disabled }) => {
  const auth = useAuth();
  const mutate = useMutationRelpy();
  const [content, setContent] = useState('');

  const handleReply = useCallback(async () => {
    await mutate.mutateAsync({ content });
    await refetch();
    setContent('');
  }, [content]);

  if (!auth?.user?.permission_keys.includes(permission.customer_service_reply_on_ticket)) {
    return <></>;
  }
  return (
    <Box sx={{ px: 4, display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
      <Typography variant='h4' sx={{ fontWeight: 400, color: alpha('#fff', 0.5) }}>
        Reply Ticket
      </Typography>
      <TextField
        disabled={disabled}
        fullWidth
        multiline
        rows={4}
        onChange={(e) => setContent(e.target.value)}
        value={content}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'primary.main',
              borderRadius: 1,
            },
            '&:hover fieldset': {
              borderColor: disabled ? 'none' : 'primary.main',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'primary.main',
            },
          },
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton
          disabled={mutate.isLoading || disabled}
          loading={mutate.isLoading}
          onClick={async () => {
            await handleReply();
          }}
          sx={{ px: 3, py: 1.2, borderRadius: 2 }}
          variant='contained'
        >
          SUBMIT REPLY
        </LoadingButton>
      </Box>
    </Box>
  );
};
