/* eslint-disable @typescript-eslint/no-unused-vars */
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import io, { Socket } from 'socket.io-client';
import { getJWT } from 'helpers/local-storage.helper';
import { encryptMessage } from 'helpers/cipher.helper';
import { REACT_APP_CHAT_SOCKET_URL } from 'config/APIs';

interface Context {
  socket?: Socket;
  sendMessage?: (
    message: string,
    user: {
      first_name: string;
      photo_link: string;
    },
    roomId: string
  ) => void;
  lastMessage?: string;
  setLastMessage: any;
}

export const SocketContext = createContext<Context>({
  socket: undefined,
  sendMessage: (
    _message: string,
    _user: { first_name: string; photo_link: string },
    _roomId: string
  ) => {},
  lastMessage: undefined,
  setLastMessage: undefined,
});

interface Props {
  children: ReactNode;
}

export const SocketsProvider: FC<Props> = ({ children }) => {
  const initDone = useRef(false);
  const [socket, setSocket] = useState<Socket>();
  const [lastMessage, setLastMessage] = useState();
  const location = useLocation();

  useEffect(() => {
    if (initDone.current === false && location.pathname !== '' && location.pathname !== '/login') {
      setSocket(
        io(REACT_APP_CHAT_SOCKET_URL, {
          extraHeaders: {
            authorization: getJWT() as any,
          },
        })
      );
      initDone.current = true;
    }
  }, [location.pathname]);

  useEffect(() => {
    if (initDone.current) {
      socket?.on('my-error', (error) => {
        console.log({ error });
      });
      socket?.on('admin-connected', (info) => {
        // console.log({ info });
      });
      socket?.on('admin-message-sent', (info) => {
        console.log({ info });
      });
      socket?.on('admin-new-message', (newMessage) => {
        console.log({ newMessage });
        setLastMessage(newMessage);
      });
    }
  }, [socket]);

  const sendMessage = (
    message: string,
    user: {
      first_name: string;
      photo_link: string;
    },
    roomId: string
  ) => {
    const content = encryptMessage(message);

    socket?.emit('admin-send-message', {
      room_id: roomId,
      content,
      sender: user,
      temp_message_id: Math.random() as any,
    });
  };

  return (
    <SocketContext.Provider
      value={{
        socket,
        sendMessage,
        lastMessage,
        setLastMessage,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketsProvider;
