const SERVER_ERROR_CODES = [
  {
    code: 1,
    message: 'There is no admin with this id',
  },
  {
    code: 2,
    message: 'Invalid credentials',
  },
  {
    code: 3,
    message: 'There is admin with this username already registered',
  },
  {
    code: 4,
    message: 'There is admin with this email already registered',
  },
  {
    code: 5,
    message: 'Admin not active',
  },
  {
    code: 6,
    message: 'Invalid credentials',
  },
  {
    code: 7,
    message: 'Session expired',
  },
  {
    code: 8,
    message: 'Notification not found',
  },
  {
    code: 9,
    message: 'App notification not found',
  },
  {
    code: 10,
    message: 'App email not found',
  },

  {
    code: 11,
    message: 'Email template not found',
  },

  {
    code: 12,
    message: 'There is no app user with this id',
  },

  {
    code: 13,
    message: 'There is no app user with this email',
  },

  {
    code: 14,
    message: 'App user photo not found',
  },
  {
    code: 15,
    message: 'Room not found',
  },
  {
    code: 16,
    message: 'Message not found',
  },
  {
    code: 17,
    message: 'Session expired',
  },
  {
    code: 19,
    message: 'you are not allowed to do this action',
  },
  {
    code: 20,
    message: 'invalid permission key',
  },
  {
    code: 21,
    message: 'invalid admin status',
  },
  {
    code: 22,
    message: `you can't register a super admin, there is super admin already registered`,
  },
  {
    code: 23,
    message: `you can't delete this user, he is already deleted`,
  },
  {
    code: 24,
    message: `you can't block this user, he is deleted`,
  },
  {
    code: 25,
    message: `you can't do this action, user should be a get married`,
  },
  {
    code: 26,
    message: `you can't do this action, user should be a wali`,
  },
  {
    code: 27,
    message: `not allowed action`,
  },
  {
    code: 28,
    message: `can't flag this photo, it's already flagged`,
  },
  {
    code: 29,
    message: `photo not found`,
  },
  {
    code: 30,
    message: `you can't check this user, you are not responsible for him`,
  },
  {
    code: 31,
    message: `you can't check this user, he is already checked`,
  },
  {
    code: 32,
    message: `role not found`,
  },
  {
    code: 33,
    message: `there is a role with this key already found`,
  },

  {
    code: 34,
    message: "can't delete this role, there is an admin has it",
  },
  {
    code: 35,
    message: "you can't block this user, he is already blocked",
  },
  {
    code: 36,
    message: "can't block this user, he is not active",
  },
  {
    code: 37,
    message: "can't unblock this user, he is not blocked",
  },
  {
    code: 38,
    message: "can't update email template, has invalid parameters",
  },
  {
    code: 39,
    message: "can't delete this admin, he is last super admin",
  },
  {
    code: 40,
    message: "can't delete this admin, you trying delete yourself",
  },
  {
    code: 41,
    message: 'Can Not Reset Password Does Not Match With Confirm',
  },
  {
    code: 42,
    message: 'Ticket Not found',
  },
  {
    code: 43,
    message: 'CAN NOT REPLY ON TICKET IT IS CLOSED',
  },
  {
    code: 44,
    message: ' you can not reopen ticket it is not closed',
  },
  {
    code: 44,
    message: 'you can not reopen ticket it is not closed before',
  },
  {
    code: 45,
    message: 'you can not set ticket status  as in progress it should be open',
  },
  {
    code: 46,
    message: 'you can not close ticket it not in progress before',
  },
  {
    code: 47,
    message: 'error support type',
  },
  {
    code: 48,
    message: 'you can not open ticket user have max count of opened tickets',
  },
  {
    code: 49,
    message: ' you can not open ticket user have opened ticket on same support type',
  },
  {
    code: 50,
    message: 'you can not set priority of ticket it is closed',
  },
  {
    code: 52,
    message: 'you can not open ticket email and phone number does not match',
  },
  {
    code: 53,
    message: 'you can not check this user under check now',
  },
  {
    code: 54,
    message: 'Email template file not found',
  },
  {
    code: 55,
    message: 'you can not assign admin to ticket he can not take responsibility',
  },
  {
    code: 56,
    message: 'you can not replay on ticket you are not responsible',
  },
  {
    code: 57,
    message: ' getting tickets overview  failed can not get more thant 90 days',
  },
  {
    code: 58,
    message: 'you can not change ticket status you are not responsible',
  },
  {
    code: 59,
    message: 'you can not assign to ticket it is closed',
  },
  {
    code: 60,
    message: "you can't start check this user, you already start check another user",
  },
  {
    code: 61,
    message: 'you can not check this user because he is deleted',
  },
  {
    code: 62,
    message: 'you can not reopen the ticket',
  },
];

const defaultErrorMessage = 'Undetected Error!';

export const getErrorMessageByCode = (code: number) => {
  const error = SERVER_ERROR_CODES.find((e) => e.code == code);
  let errorMessage = defaultErrorMessage;
  if (error != null) {
    errorMessage = error.message;
  }
  return errorMessage;
};
