import { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  alpha,
  // Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  // ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import Settings from 'icons/Settings';
import Account from 'icons/Account';
import { showSuccessSnackbarMessage } from 'helpers/snackbar.helper';
import useAuth from 'hooks/useAuth';

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const auth = useAuth();

  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    const onLogoutSuccess = async () => {
      await auth.logout();
      showSuccessSnackbarMessage('Logout successfully');
    };

    try {
      await onLogoutSuccess();
      await handleClose();
      await navigate('/login');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          columnGap: '16px',
          background: '#161819',
          p: 1,
          borderRadius: '6666px',
        }}
      >
        <Account />
        <Settings />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240, borderRadius: '8px' },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color='textPrimary' variant='subtitle2'>
            {auth?.user?.full_name}
          </Typography>
        </Box>
        <Divider />
        <Box>
          <MenuItem component={RouterLink} to='/dashboard/profile'>
            <ListItemText primary={<Typography color='textPrimary'>Profile</Typography>} />
          </MenuItem>

          {[
            'setting-view-contact-information',
            'setting-edit-contact-information',
            'setting-view-terms-condition',
            'setting-edit-terms-condition',
            'setting-view-privacy-policy',
            'setting-edit-privacy-policy',
          ].some((i) => auth.user?.permission_keys.includes(i)) && (
            <MenuItem component={RouterLink} to='/dashboard/app-settings'>
              <ListItemText primary={<Typography color='textPrimary'>Settings</Typography>} />
            </MenuItem>
          )}
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            sx={{
              height: '44px',
              borderRadius: '8px',
              color: 'primary.main',
              background: (theme) => alpha(theme.palette.primary.main, 0.1),
              display: 'flex',
              justifyContent: 'flex-start',
              px: '12px !important',
              border: 'none',
            }}
            fullWidth
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
