import { createSvgIcon } from '@mui/material/utils';

const Male = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMid meet' viewBox='0 0 24 24'>
    <circle cx='12' cy='4' r='2' fill='currentColor' />
    <path fill='currentColor' d='M15 7H9a1 1 0 0 0-1 1v7h2v7h4v-7h2V8a1 1 0 0 0-1-1z' />
  </svg>,
  'Male'
);

export default Male;
