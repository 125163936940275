/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-redeclare */
import { useEffect, useRef, useState } from 'react';
import type { ElementType, FC } from 'react';
import ScrollBar from 'components/Scrollbar';

import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Popover,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import BellIcon from 'icons/Bell';
import ChatAltIcon from 'icons/ChatAlt';
import CreditCardIcon from 'icons/CreditCard';
import ShoppingCartIcon from 'icons/ShoppingCart';
import { useQuery } from 'react-query';
import { curd } from 'api/curd';
import { getCurrentTime } from 'helpers/moment.helper';
import { useMutationMarkNotificationsAsRead } from 'queries/mark-all-notifications-read';
import { useMutationMarkNotificationAsRead } from 'queries/mark-notification-read';
import { useNavigate } from 'react-router-dom';
import { NotificationTypes } from 'config/notification-type';
import { onMessageListener } from 'config/Firebase';
import { UserTypes } from 'dto/user';
import { makeTimeFormatDateFNSHelper } from 'helpers/date-fns.helper';
import { pushNewTicketMessageFromNotification } from 'sections/ticket-details/messages';

interface FCMNotification {
  from: string;
  messageId: string;
  notification: {
    title: string;
    body: string;
    image: string;
  };
  data: any;
}

class Notification {
  id: string | undefined;

  type: string | undefined;

  title: string | undefined;

  content: string | undefined;

  icon_url: string | undefined;

  data: any;

  is_read: boolean | undefined;

  created_at: string | undefined;

  constructor(n: FCMNotification) {
    this.id = n.data.id;
    this.type = n.data.type;
    this.title = n.notification.title;
    this.content = n.notification.body;
    this.icon_url = n.notification.image;
    this.data = n.data;
    this.is_read = false;
    this.created_at = getCurrentTime();
  }
}

const iconsMap: Record<string, ElementType> = {
  item_shipped: ShoppingCartIcon,
  new_message: ChatAltIcon,
  order_placed: CreditCardIcon,
};

let oldNotificationsData: Notification[] = [];
let newNotificationsByListener: Notification[] = [];

const pushNewNotifications = (
  OldNotifications: Notification[],
  NewNotifications: Notification[]
) => {
  for (let i = 0; i < NewNotifications.length; i++) {
    const notification = NewNotifications[i];
    const found = OldNotifications.find((n) => n.id == notification.id);
    if (found == null) {
      OldNotifications.push(notification);
    }
  }

  return OldNotifications;
};

interface NotificationItemProps {
  notification: Notification;
  page: number;
  limit: number;
  onClick: () => void;
}

const NotificationItem: FC<NotificationItemProps> = ({ notification, page, limit, onClick }) => {
  const navigate = useNavigate();

  const mutationMarkNotificationAsRead = useMutationMarkNotificationAsRead(
    notification.id,
    page,
    limit
  );
  const handlingClickMarkAsRead = (id: string | undefined) => {
    mutationMarkNotificationAsRead.mutate();
  };

  const handleNavigateToNotificationDetails = () => {
    let navigateTo = null;
    switch (notification.type) {
      case NotificationTypes.USER_REPORTED:
      case NotificationTypes.NEW_USER_REGISTER: {
        let userTypeURL = 'users';
        if (notification.data.user_type == UserTypes.WALI) {
          userTypeURL = 'walis';
        }

        navigateTo = `/dashboard/${userTypeURL}/${notification.data.user_id}`;
        break;
      }
      case NotificationTypes.NEW_USER_UPDATE: {
        let userTypeURL = 'users';
        if (notification.data.user_type == UserTypes.WALI) {
          userTypeURL = 'walis';
        }
        navigateTo = `/dashboard/${userTypeURL}/${notification.data.user_id}`;
        break;
      }
      case NotificationTypes.TICKET_CREATED:
      case NotificationTypes.ASSIGN_TICKET:
      case NotificationTypes.REOPEN_TICKET:
      case NotificationTypes.TICKET_REPLY: {
        navigateTo = `/dashboard/support-system/ticket/${notification.data.ticket_id}`;
        break;
      }
      default:
        break;
    }

    if (navigateTo != null) {
      navigate(navigateTo, { replace: true });
    }
    onClick();
  };

  return (
    <ListItem
      divider
      button
      key={notification.id}
      sx={{ backgroundColor: notification.is_read ? '#333' : '#444' }}
      onClick={() => {
        handlingClickMarkAsRead(notification.id);
        handleNavigateToNotificationDetails();
      }}
    >
      <ListItemAvatar>
        <Avatar
          alt={notification.type}
          src={notification.icon_url}
          sx={{
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
          }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={notification.title}
        secondary={
          <>
            <Typography variant='body1'>{notification.content}</Typography>
            <Typography variant='body2' color='GrayText'>
              {makeTimeFormatDateFNSHelper(notification.created_at)}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};

const NotificationSkeleton: FC = () => {
  return (
    <ListItem key='loading'>
      <ListItemAvatar>
        <Skeleton variant='circular' width={40} height={40} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Link color='textPrimary' sx={{ cursor: 'pointer' }} underline='none' variant='subtitle2'>
            <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
          </Link>
        }
        secondary={<Skeleton variant='rectangular' width={210} height={60} />}
      />
    </ListItem>
  );
};

const NotificationsPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const [page, setPage] = useState<number>(0);
  const limit = 10;
  const markNotificationsAsReadMutate = useMutationMarkNotificationsAsRead(page, limit);

  const handleIncreasePage = () => {
    setPage(page + 1);
  };

  const query = useQuery(`dashboard/profile/notification?page=${page}&limit=${limit}`, () =>
    curd.getAll({ url: `dashboard/profile/notification`, params: { page, limit } })
  );

  const myNotifications: Notification[] = query.data?.data?.data?.notifications || [];
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(
    query.data?.data?.data?.unread_notifications_count
  );
  const increaseNotificationsCount = (count: number) => {
    if (unreadNotificationsCount == null) {
      setUnreadNotificationsCount(count);
    } else {
      setUnreadNotificationsCount(unreadNotificationsCount + count);
    }
  };

  const hasMoreNotifications: boolean = query.data?.data?.data?.has_more_notifications;
  const isLoading: boolean = query.isLoading;

  let MyAllNotifications = pushNewNotifications(oldNotificationsData, myNotifications);
  MyAllNotifications = pushNewNotifications(newNotificationsByListener, MyAllNotifications);
  newNotificationsByListener = [];
  oldNotificationsData = MyAllNotifications;

  const handleScroll = (e: any) => {
    const deference = e.target.scrollHeight - e.target.scrollTop;
    const clientHeight = e.target.clientHeight;
    const bottom = clientHeight >= deference - 5;

    console.log('yessssss', hasMoreNotifications, { bottom });

    if (bottom && hasMoreNotifications) {
      handleIncreasePage();
    }
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handlingClickMarkAllAsRead = () => {
    markNotificationsAsReadMutate.mutate();
    oldNotificationsData = oldNotificationsData.map((n) => {
      n.is_read = true;
      return n;
    });

    setUnreadNotificationsCount(0);
  };

  onMessageListener()
    .then((payload: any) => {
      increaseNotificationsCount(1);
      // if the user got a new ticket reply and he is in the same ticket messages page, then push this reply
      if (payload?.data?.type == NotificationTypes.TICKET_REPLY) {
        const currentPath = window.location.pathname;
        if (
          currentPath.startsWith('/dashboard/support-system/ticket/') &&
          currentPath.includes(payload?.data?.ticket_id)
        ) {
          pushNewTicketMessageFromNotification();
        }
      }

      const newNotification = new Notification(payload as FCMNotification);
      newNotificationsByListener = [newNotification];
    })
    .catch((err) => console.log('failed: ', err));

  useEffect(() => {
    setUnreadNotificationsCount(query.data?.data?.data?.unread_notifications_count);
  }, [query.data?.data?.data]);

  return (
    <>
      <Tooltip title='Notifications'>
        <IconButton
          sx={{
            background: '#161819',
            flex: 'center',
            placeItems: 'center',
            placeContent: 'center',
            height: 36,
            width: 36,
          }}
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge color='error' badgeContent={unreadNotificationsCount}>
            <BellIcon
              sx={{
                mr: -0.5,
              }}
              fontSize='small'
            />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color='textPrimary' variant='h6'>
            Notifications
          </Typography>
        </Box>
        {MyAllNotifications.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color='textPrimary' variant='subtitle2'>
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1}>
              <Grid item xs={12}>
                <List
                  component={ScrollBar as any}
                  onScroll={handleScroll}
                  disablePadding
                  sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 310,
                    '& ul': { padding: 0 },
                  }}
                >
                  {MyAllNotifications.map((notification) => {
                    return (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        page={page}
                        limit={limit}
                        onClick={() => {
                          if (!notification.is_read) {
                            increaseNotificationsCount(-1);
                          }

                          handleClose();
                        }}
                      />
                    );
                  })}

                  {isLoading ? (
                    <ListItem divider key='loading'>
                      <NotificationSkeleton />
                    </ListItem>
                  ) : (
                    <></>
                  )}
                </List>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1,
                  }}
                >
                  <Button
                    color='primary'
                    size='small'
                    variant='text'
                    onClick={handlingClickMarkAllAsRead}
                  >
                    Mark all as read
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
