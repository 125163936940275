import { createSvgIcon } from '@mui/material/utils';

const Match = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 48 48'>
    <path
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='m18.044 13.446l2.437 11.338a2.335 2.335 0 0 0 2.774 1.792l2.956-.635l3.296 2.13l2.13-3.297l2.956-.635a2.335 2.335 0 0 0 1.792-2.774l-2.438-11.338a2.335 2.335 0 0 0-2.773-1.792l-11.338 2.438a2.335 2.335 0 0 0-1.792 2.773'
    ></path>
    <path
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M31.595 15.408a2.534 2.534 0 0 0-3.01-1.945a2.53 2.53 0 0 0-1.862 1.657a2.53 2.53 0 0 0-2.379-.745a2.534 2.534 0 0 0-1.588 3.86c1.326 2.18 5.494 3.99 5.494 3.99s3.057-3.363 3.37-5.895a2.54 2.54 0 0 0-.025-.922M7.27 37.51l5.22-2.046c.958-.381 1.29-1.23 1.396-1.701c.214-.943-.04-1.972-.652-2.628a2.142 2.142 0 0 1 .116-3.024a2.132 2.132 0 0 1 3.023.116c1.226 1.324 1.88 3.142 1.847 4.976l8.084-3.17a2.144 2.144 0 0 1 2.776 1.208a2.15 2.15 0 0 1-1.208 2.776l-1.716.675'
    ></path>
    <path
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M22.647 39.999a2.14 2.14 0 0 1-1.2-4.096l3.226-1.214a2.14 2.14 0 0 1 1.507 4.006l-3.226 1.214a2.18 2.18 0 0 1-.307.09m1.251 4.093a2.14 2.14 0 0 1-.935-4.16l1.937-.612a2.13 2.13 0 0 1 2.684 1.397a2.14 2.14 0 0 1-1.397 2.684l-1.937.611a2.117 2.117 0 0 1-.352.08'
    ></path>
    <path
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M20.407 45.203c.937-.339 2.442-1.231 2.442-1.231'
    ></path>
    <circle
      cx={24}
      cy={24}
      r={21.5}
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></circle>
  </svg>,
  'Match'
);

export default Match;
