import { FC, useRef, useState } from 'react';
import { useEffect } from 'react';

import { useRoutes } from 'react-router-dom';
// import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import './i18n';
// import RTL from './components/RTL';
// import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import { routes, SuperRoutes, DevRoutes } from './routes';
import { createTheme } from './theme';
import './index.css';

import 'config/Firebase';
import { removeJWT } from 'helpers/local-storage.helper';
import 'react-lazy-load-image-component/src/effects/blur.css';

const contentHeight = 100;
let windowHeight = window.innerHeight;
export const getWindowHeight = () => {
  return windowHeight - contentHeight;
};

const App: FC = () => {
  const getRoute = () => {
    if (
      window?.location?.hostname.includes(
        process.env.REACT_APP_PREFIX_SUPER_DEV_ADMIN_DOMAIN as string
      )
    )
      return DevRoutes;
    if (
      window?.location?.hostname.includes(process.env.REACT_APP_PREFIX_SUPER_ADMIN_DOMAIN as string)
    )
      return SuperRoutes;

    return routes;
  };

  const content = useRoutes(getRoute());
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  const handleTabClosing = () => {
    if (
      //@ts-ignore
      localStorage.getItem('remember_me') == false ||
      //@ts-ignore
      localStorage.getItem('remember_me') == null
    ) {
      removeJWT();
    }
  };

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing);
    return () => {
      window.removeEventListener('unload', handleTabClosing);
    };
  }, []);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  const ref = useRef<any>(null);
  windowHeight = window.innerHeight;

  const [, setHeight] = useState(windowHeight);
  useEffect(() => {
    function handleResize() {
      windowHeight = window.innerHeight;
      setHeight(windowHeight);
    }

    window.addEventListener('resize', handleResize);
  });

  return (
    <div ref={ref}>
      <ThemeProvider theme={theme}>
        {auth.isInitialized === true ? content : <SplashScreen />}
      </ThemeProvider>
    </div>
  );
};

export default App;
