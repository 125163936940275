import { createSvgIcon } from '@mui/material/utils';

const Email = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='email'
    width='17.432'
    height='19.811'
    viewBox='0 0 17.432 19.811'
  >
    <path
      id='Path_2433'
      data-name='Path 2433'
      d='M12,36.342v9.91A1.245,1.245,0,0,0,13.245,47.5H28.187a1.245,1.245,0,0,0,1.245-1.245v-9.91a1.245,1.245,0,0,1-.435.945L22.336,43A2.49,2.49,0,0,1,19.1,43l-6.66-5.709A1.245,1.245,0,0,1,12,36.342Z'
      transform='translate(-12 -27.687)'
      fill='currentColor'
      fillRule='evenodd'
    />
    <path
      id='Path_2434'
      data-name='Path 2434'
      d='M29.432,16.463v-.672A1.245,1.245,0,0,0,29,14.846l-1.744-1.495v6.226L29,18.081a1.245,1.245,0,0,0,.435-.945v-.672ZM14.179,19.576l-1.744-1.495A1.245,1.245,0,0,1,12,17.136V15.791a1.245,1.245,0,0,1,.435-.945l1.744-1.495Zm2.905-8.716h7.263L22.336,9.137a2.49,2.49,0,0,0-3.241,0Z'
      transform='translate(-12 -8.537)'
      fill='currentColor'
      fillRule='evenodd'
    />
    <path
      id='Path_2435'
      data-name='Path 2435'
      d='M30.7,23.395l.405.473a.623.623,0,0,0,.217-.473Zm-13.074,0H17a.623.623,0,0,0,.217.473Zm4.916,4.214-.405.473Zm3.241,0-.405-.473Zm5.539-4.214V16.179H30.076v7.216Zm0-7.216A2.179,2.179,0,0,0,29.142,14v1.245a.934.934,0,0,1,.934.934ZM29.142,14H19.181v1.245h9.961Zm-9.961,0A2.179,2.179,0,0,0,17,16.179h1.245a.934.934,0,0,1,.934-.934ZM17,16.179v7.216h1.245V16.179Zm5.944,10.957L18.03,22.922l-.81.945,4.916,4.214Zm2.431,0a1.868,1.868,0,0,1-2.431,0l-.81.945a3.113,3.113,0,0,0,4.052,0Zm4.916-4.214-4.916,4.214.81.945L31.1,23.868Z'
      transform='translate(-15.445 -12.3)'
      fill='currentColor'
    />
    <path
      id='Path_2436'
      data-name='Path 2436'
      d='M26.625,26a.623.623,0,0,0,0,1.245Zm4.98,1.245a.623.623,0,1,0,0-1.245Zm-4.98,1.245a.623.623,0,0,0,0,1.245ZM34.1,29.736a.623.623,0,0,0,0-1.245Zm-7.471,1.245a.623.623,0,1,0,0,1.245Zm4.98,1.245a.623.623,0,1,0,0-1.245Zm-4.98-4.98h4.98V26h-4.98Zm0,2.49H34.1V28.491H26.625Zm0,2.49h4.98V30.981h-4.98Z'
      transform='translate(-21.643 -20.565)'
      fill='currentColor'
    />
  </svg>,
  'Email'
);

export default Email;
