import { useRef, useEffect, useState } from 'react';
import type { FC } from 'react';
import { Message } from './Message';
import { Box } from '@mui/material';
import { CSMessageResponse } from 'dto/ticket/ticket-message/inedx';

interface Props {
  messages: CSMessageResponse[];
}

export const AllMessages: FC<Props> = ({ messages }) => {
  const rootRef = useRef<any>(null);
  const [myMessage, setMessage] = useState<CSMessageResponse[]>([]);

  const scrollToBottom = () => {
    // eslint-disable-next-line no-underscore-dangle
    if (rootRef?.current) {
      // eslint-disable-next-line no-underscore-dangle
      rootRef.current.scrollTop = rootRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
    if (messages?.length) setMessage(messages);
  }, [messages?.length]);

  return (
    <Box sx={{ p: 2, display: 'flex', gap: 3, flexDirection: 'column', pl: 4 }}>
      {[...myMessage].reverse().map((message) => {
        return <Message message={message} key={message.id} />;
      })}
    </Box>
  );
};
