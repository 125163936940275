import { createSvgIcon } from '@mui/material/utils';

const Subscription = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'>
    <g id='subs' transform='translate(0 -0.115)'>
      <rect
        id='Rectangle_2638'
        data-name='Rectangle 2638'
        width='22'
        height='22'
        transform='translate(0 0.115)'
        fill='none'
      />
      <path
        id='Path_2507'
        data-name='Path 2507'
        d='M96,56h11.923a.692.692,0,0,1,.745.621v1.926a.654.654,0,0,1-.6.606,2.439,2.439,0,0,0,0,4.877.654.654,0,0,1,.6.606v1.926a.692.692,0,0,1-.745.621H96Z'
        transform='translate(-89.501 -50.918)'
        fill='currentColor'
      />
      <line
        id='Line_472'
        data-name='Line 472'
        y2='11'
        transform='translate(7 5.115)'
        fill='none'
        stroke='#4c4d4e'
        strokeLinejoin='round'
        strokeWidth='1'
      />
      <path
        id='Path_2508'
        data-name='Path 2508'
        d='M24,64.635a.622.622,0,0,1,.523-.606,2.47,2.47,0,0,0,0-4.877A.622.622,0,0,1,24,58.547V56.621A.638.638,0,0,1,24.653,56H40.336a.638.638,0,0,1,.653.621v1.926a.622.622,0,0,1-.523.606,2.47,2.47,0,0,0,0,4.877.622.622,0,0,1,.523.606v1.926a.638.638,0,0,1-.653.621H24.653A.638.638,0,0,1,24,66.561Z'
        transform='translate(-21.822 -50.918)'
        fill='none'
        stroke='#4c4d4e'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1'
      />
    </g>
  </svg>,
  'Subscription'
);

export default Subscription;
