import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { curd } from 'api/curd';
import { useNavigate } from 'react-router-dom';

interface RedirectToLogin {
  children: ReactNode;
}

const RedirectToLogin: FC<RedirectToLogin> = (props) => {
  const navigate = useNavigate();
  const { children } = props;

  useEffect(() => {
    curd.getAll({ url: 'super-dashboard/auth/is-there-super-admin' }).then((res) => {
      if (res.data.data.result) {
        navigate('/login', { replace: true });
      }
    });
  }, []);

  return <>{children}</>;
};

RedirectToLogin.propTypes = {
  children: PropTypes.node,
};

export default RedirectToLogin;
