import { createSvgIcon } from '@mui/material/utils';

const Message = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='message'
    width='21.728'
    height='21.728'
    viewBox='0 0 21.728 21.728'
  >
    <path
      id='Path_2419'
      data-name='Path 2419'
      d='M21.716,59.152H9.222A1.222,1.222,0,0,1,8,57.93a4.818,4.818,0,0,1,3.154-4.522l.48-.177a11.1,11.1,0,0,1,7.669,0l.48.177a4.818,4.818,0,0,1,3.154,4.522A1.222,1.222,0,0,1,21.716,59.152Z'
      transform='translate(-8 -37.424)'
      fill='currentColor'
    />
    <path
      id='Path_2420'
      data-name='Path 2420'
      d='M19.908,27.773a4.168,4.168,0,0,0,5.949-2.834l.05-.231a4.355,4.355,0,0,0-.79-3.565l-.08-.105a4.088,4.088,0,0,0-6.5,0l-.08.105a4.355,4.355,0,0,0-.79,3.565l.05.231A4.168,4.168,0,0,0,19.908,27.773Z'
      transform='translate(-14.316 -15.547)'
      fill='currentColor'
    />
    <path
      id='Path_2421'
      data-name='Path 2421'
      d='M49.358,8A1.358,1.358,0,0,0,48,9.358v3.686A1.358,1.358,0,0,0,49.358,14.4h.388v1.746L52.074,14.4H54.79a1.358,1.358,0,0,0,1.358-1.358V9.358A1.358,1.358,0,0,0,54.79,8Z'
      transform='translate(-34.42 -8)'
      fill='currentColor'
      fillRule='evenodd'
    />
  </svg>,
  'Message'
);

export default Message;
