import { createSvgIcon } from '@mui/material/utils';

const Coins = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 48 48'>
    <g fill='currentColor' fillRule='evenodd' clipRule='evenodd'>
      <path d='M37.973 27.975A12.939 12.939 0 0 0 40 21c0-7.18-5.82-13-13-13c-2.567 0-4.96.744-6.975 2.027a16.953 16.953 0 0 0-3.954.698A14.958 14.958 0 0 1 27 6c8.284 0 15 6.716 15 15a14.96 14.96 0 0 1-4.725 10.929c.381-1.263.62-2.587.697-3.954'></path>
      <path d='M39.933 28.603a15.065 15.065 0 0 1-2.658 3.326c.381-1.263.62-2.587.697-3.954A12.939 12.939 0 0 0 40 21c0-7.18-5.82-13-13-13c-2.567 0-4.96.744-6.975 2.027a16.953 16.953 0 0 0-3.954.698a15.067 15.067 0 0 1 3.326-2.658A14.93 14.93 0 0 1 27 6c8.284 0 15 6.716 15 15a14.92 14.92 0 0 1-2.067 7.603'></path>
      <path d='M36 27c0 8.284-6.716 15-15 15c-8.284 0-15-6.716-15-15c0-8.284 6.716-15 15-15c8.284 0 15 6.716 15 15m-16-5a2 2 0 1 0 0 4zm2-2v-1h-2v1a4 4 0 0 0 0 8v4c-.87 0-1.611-.555-1.887-1.333a1 1 0 1 0-1.885.666A4.001 4.001 0 0 0 20 34v1h2v-1a4 4 0 0 0 0-8v-4c.87 0 1.611.555 1.887 1.333a1 1 0 1 0 1.885-.666A4.001 4.001 0 0 0 22 20m0 8v4a2 2 0 1 0 0-4'></path>
    </g>
  </svg>,
  'Coins'
);

export default Coins;
