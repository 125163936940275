import moment from 'moment';
import * as momentTZ from 'moment-timezone';

export const getMyTimezone = () => {
  return momentTZ.tz.guess();
};

export const getCurrentTime = () => {
  return moment.utc().toString();
};

export const makeTimeFormatHelper = (
  date: string | undefined,
  format: string = 'MMMM DDt\\h, YYYY, hh:mm:ss a'
) => {
  if (date == undefined) return '';
  const timeZone = getCurrentTime();
  return moment(date).tz(timeZone).format(format).toString();
};

export const makeDayFormatHelper = (date: string) => {
  return moment.utc(date).format('DD MMM').toString();
};

export const makeMonthFormatHelper = (date: string) => {
  return moment.utc(date).format('MMM YYYY').toString();
};

export const getTheNDayBeforeThisDay = (day: string, n: number) => {
  return moment.utc(day).add(-n, 'days').format('YYYY-MM-DD');
};

export const getCurrentDay = () => {
  return moment.utc().format('YYYY-MM-DD').toString();
};

export const getCurrentMonth = () => {
  return moment.utc().format('YYYY-MM').toString();
};

export const getCurrentYear = () => {
  return moment.utc().format('YYYY').toString();
};

export const generateLastXMonthsFromThisMonth = (month: string, count: number) => {
  const months: string[] = [month];

  let CurrentMonth = month;

  while (count > 0) {
    const PreviousMonth = moment.utc(CurrentMonth).add(-1, 'month').format('YYYY-MM').toString();
    months.push(PreviousMonth);
    CurrentMonth = PreviousMonth;
    count--;
  }
  return months;
};

export const generateLastXYearsFromThisYear = (year: string, count: number) => {
  const years: string[] = [year];

  let CurrentMonth = year;

  while (count > 0) {
    const PreviousYear = moment.utc(CurrentMonth).add(-1, 'year').format('YYYY').toString();
    years.push(PreviousYear);
    CurrentMonth = PreviousYear;
    count--;
  }
  return years;
};

export const convertSecondesToHHMMSS = (secs: number) => {
  return moment.utc(secs * 1000).format('HH:mm:ss');
};

export const convertSecondesToDayHHMMSS = (secs: number) => {
  const duration = moment.duration(secs, 'seconds');

  const months = Math.floor(duration.asMonths());
  const remainingDays = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const remainingSeconds = duration.seconds();

  let formattedDuration = '';

  if (months > 0) {
    formattedDuration += `${months} Months `;
  }

  if (remainingDays > 0) {
    formattedDuration += `${remainingDays} Days `;
  }

  if (hours > 0) {
    formattedDuration += `${hours} h `;
  }

  if (minutes > 0) {
    formattedDuration += `${minutes} m `;
  }
  formattedDuration += `${remainingSeconds} s`;

  return formattedDuration.trim();
};

export const convertSecondesToDayHHMM = (secs: number) => {
  const duration = moment.duration(secs, 'seconds');

  const months = Math.floor(duration.asMonths());
  const remainingDays = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  let formattedDuration = '';

  if (months > 0) {
    formattedDuration += `${months} Months `;
  }

  if (remainingDays > 0) {
    formattedDuration += `${remainingDays} Days `;
  }

  if (hours > 0) {
    formattedDuration += `${hours} h `;
  }

  if (minutes > 0) {
    formattedDuration += `${minutes} m `;
  }

  return formattedDuration.trim();
};
