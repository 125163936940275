import { createSvgIcon } from '@mui/material/utils';

const Trending = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='20.4' height='18.025' viewBox='0 0 20.4 18.025'>
    <g id='trtending' transform='translate(0.7 0.7)' opacity='0.5'>
      <path
        id='Path_2410'
        data-name='Path 2410'
        d='M6.173,32.734a.567.567,0,0,0,0,.816.6.6,0,0,0,.836,0Zm16.311-6.121a.567.567,0,0,0,0-.816.6.6,0,0,0-.836,0ZM7.01,33.55l4.914-4.8-.836-.816-4.914,4.8Zm5.751-4.8L15.27,31.2l.836-.816L13.6,27.939ZM17.779,31.2l4.705-4.591-.836-.816-4.705,4.591Zm-2.509,0a1.806,1.806,0,0,0,2.509,0l-.836-.816a.6.6,0,0,1-.836,0Zm-3.346-2.449a.6.6,0,0,1,.836,0l.836-.816a1.806,1.806,0,0,0-2.509,0Z'
        transform='translate(-6 -21.362)'
        fill='currentColor'
      />
      <path
        id='Path_2411'
        data-name='Path 2411'
        d='M57.829,23.406,54.48,20.058a.536.536,0,0,1,.379-.916h2.969a.916.916,0,0,1,.916.916v2.969A.536.536,0,0,1,57.829,23.406Z'
        transform='translate(-40.107 -16.856)'
        fill='currentColor'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path
        id='Path_2412'
        data-name='Path 2412'
        d='M8,12V28.031a.594.594,0,0,0,.594.594H27'
        transform='translate(-8 -12)'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.4'
      />
    </g>
  </svg>,
  'Trending'
);

export default Trending;
