import { useState, memo } from 'react';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material/styles';
import DashboardNavbar from 'components/layouts/header/DashboardNavbar';
import DashboardSidebar from 'components/layouts/navbar/DashboardSidebar';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import { NAVBAR } from 'constants/config';
import useResponsive from 'hooks/useResponsive';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({}) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  const { isCollapse } = useCollapseDrawer();
  const isMobile = useResponsive('down', 'lg');

  return (
    <DashboardLayoutRoot>
      <DashboardSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <DashboardNavbar onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />

      <DashboardLayoutWrapper
        sx={{
          transition: (theme) =>
            theme.transitions.create('padding', {
              duration: theme.transitions.duration.standard,
            }),
          ...(isCollapse && {
            paddingLeft: `${NAVBAR.DASHBOARD_COLLAPSE_WIDTH + 60}px`,
          }),
          ...(!isCollapse && {
            paddingLeft: isMobile ? 0 : `${NAVBAR.BASE_WIDTH + 40}px`,
          }),
        }}
      >
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default memo(DashboardLayout);
