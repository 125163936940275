import { createSvgIcon } from '@mui/material/utils';

const UserDelete = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
    <path
      fill='currentColor'
      d='M10.596 0c3.226 0 5.842 2.566 5.842 5.732a5.676 5.676 0 0 1-2.047 4.359c.274.133.523.267.748.402c.485.29.988.65 1.51 1.079a.679.679 0 0 1 .088.969a.71.71 0 0 1-.988.086a11.197 11.197 0 0 0-1.34-.96a11.62 11.62 0 0 0-1.424-.703a5.91 5.91 0 0 1-2.39.5a5.91 5.91 0 0 1-2.615-.605l-.042.02c-1.977.756-3.42 1.874-4.35 3.358c-.935 1.489-1.317 3.153-1.146 5.014a.692.692 0 0 1-.636.746a.697.697 0 0 1-.761-.623c-.197-2.152.253-4.113 1.348-5.858c.964-1.536 2.38-2.73 4.23-3.581a5.664 5.664 0 0 1-1.87-4.203C4.753 2.566 7.37 0 10.596 0m7.223 14.24a.698.698 0 0 1 .978-.003c.27.266.27.698.002.965l-1.192 1.179l1.192 1.18a.675.675 0 0 1-.002.964a.698.698 0 0 1-.978-.001l-1.187-1.177l-1.187 1.177a.698.698 0 0 1-.891.073l-.086-.072a.675.675 0 0 1-.002-.964l1.19-1.18l-1.19-1.18a.675.675 0 0 1 .002-.964a.698.698 0 0 1 .977.002l1.187 1.176ZM10.596 1.375c-2.453 0-4.44 1.95-4.44 4.356c0 2.406 1.987 4.357 4.44 4.357c2.452 0 4.44-1.95 4.44-4.357c0-2.406-1.988-4.356-4.44-4.356'
    />
  </svg>,
  'UserDelete'
);

export default UserDelete;
