import { createSvgIcon } from '@mui/material';

const CSS = createSvgIcon(
  <svg
    height='100%'
    width='100%'
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
  >
    <circle cx='256' cy='256' r='256' />
    <g>
      <path
        style={{
          color: '#000',
        }}
        d='M184.512,271.52h20.64v3.728c0,15.072-2.752,24.832-8.256,29.248s-17.664,6.624-36.512,6.624
		c-21.392,0-34.544-3.488-39.472-10.464c-4.928-7.008-7.376-25.664-7.376-56.064c0-17.888,3.328-29.648,10-35.28
		c6.672-5.632,20.608-8.448,41.84-8.448c15.456,0,25.76,2.32,30.96,6.944c5.184,4.64,7.776,13.824,7.776,27.6l0.08,2.448h-20.64
		v-2.768c0-7.088-1.328-11.648-4-13.632s-8.784-2.976-18.304-2.976c-12.768,0-20.416,1.568-22.992,4.672
		c-2.576,3.12-3.856,12.384-3.856,27.792c0,20.752,1.152,33.024,3.456,36.832c2.32,3.808,9.744,5.712,22.336,5.712
		c10.224,0,16.848-1.04,19.888-3.168c3.04-2.112,4.56-6.784,4.56-13.968L184.512,271.52z'
      />
      <path
        style={{
          color: '#000',
        }}
        d='M298.512,233.408h-20.096c-0.096-0.992-0.16-1.744-0.16-2.224c-0.464-6.096-1.808-9.92-4.032-11.488
		c-2.224-1.552-7.472-2.336-15.712-2.336c-9.744,0-16.112,0.896-19.088,2.688c-3.008,1.792-4.48,5.6-4.48,11.36
		c0,6.832,1.216,10.928,3.648,12.304c2.432,1.392,10.48,2.432,24.128,3.184c16.16,0.896,26.592,3.2,31.344,6.912
		c4.72,3.696,7.088,11.408,7.088,23.088c0,14.4-2.784,23.696-8.336,27.904c-5.568,4.224-17.808,6.32-36.752,6.32
		c-17.04,0-28.352-2.064-33.936-6.192s-8.368-12.48-8.368-25.088l-0.08-3.968h20l0.08,2.32c0,7.568,1.328,12.192,3.968,13.888
		c2.656,1.68,9.872,2.528,21.68,2.528c9.184,0,15.056-0.976,17.632-2.944c2.528-1.952,3.808-6.48,3.808-13.568
		c0-5.248-0.976-8.736-2.912-10.448s-6.096-2.768-12.496-3.12l-11.36-0.72c-17.136-0.992-28.112-3.392-32.864-7.136
		c-4.768-3.76-7.152-11.824-7.152-24.224c0-12.656,2.88-21.072,8.624-25.28c5.728-4.224,17.2-6.32,34.4-6.32
		c16.32,0,27.28,1.92,32.944,5.792c5.68,3.856,8.496,11.408,8.496,22.624v4.144H298.512z'
      />
      <path
        style={{
          color: '#000',
        }}
        d='M395.856,233.408H375.76c-0.112-0.992-0.16-1.744-0.16-2.224c-0.464-6.096-1.824-9.92-4.048-11.488
		c-2.224-1.552-7.472-2.336-15.712-2.336c-9.728,0-16.112,0.896-19.088,2.688s-4.48,5.6-4.48,11.36
		c0,6.832,1.216,10.928,3.664,12.304c2.416,1.392,10.464,2.432,24.128,3.184c16.144,0.896,26.592,3.2,31.328,6.912
		s7.088,11.408,7.088,23.088c0,14.4-2.768,23.696-8.336,27.904c-5.568,4.224-17.808,6.32-36.768,6.32
		c-17.024,0-28.336-2.064-33.936-6.192c-5.584-4.128-8.368-12.48-8.368-25.088l-0.08-3.968h20.016l0.08,2.32
		c0,7.568,1.312,12.192,3.968,13.888c2.64,1.68,9.856,2.528,21.664,2.528c9.2,0,15.072-0.976,17.632-2.944
		c2.528-1.952,3.808-6.48,3.808-13.568c0-5.248-0.976-8.736-2.912-10.448c-1.92-1.712-6.096-2.768-12.496-3.12l-11.36-0.72
		c-17.136-0.992-28.096-3.392-32.864-7.136c-4.768-3.76-7.136-11.824-7.136-24.224c0-12.656,2.864-21.072,8.608-25.28
		c5.728-4.224,17.216-6.32,34.4-6.32c16.32,0,27.296,1.92,32.96,5.792c5.664,3.856,8.496,11.408,8.496,22.624L395.856,233.408
		L395.856,233.408z'
      />
    </g>
  </svg>,
  'CSS'
);

export default CSS;
