import { createSvgIcon } from '@mui/material/utils';

const Admin = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' id='admin' width='24' height='24' viewBox='0 0 24 24'>
    <g id='Boundary' stroke='rgba(0,0,0,0)' strokeWidth='1' opacity='0'>
      <rect width='24' height='24' stroke='none' />
      <rect x='0.5' y='0.5' width='23' height='23' fill='none' />
    </g>
    <path
      id='Path_account-tie-outline'
      data-name='Path / account-tie-outline'
      d='M16.36,12.76C18.31,13.42,20,14.5,20,16v5H4V16c0-1.5,1.69-2.58,3.65-3.24L8.27,14l.23.5C7,14.96,5.9,15.62,5.9,16v3.1h4.22L11,14.03l-.94-1.88A17.271,17.271,0,0,1,12,12.03a17.271,17.271,0,0,1,1.94.12L13,14.03l.88,5.07H18.1V16c0-.38-1.1-1.04-2.6-1.5l.23-.5.63-1.24'
      fill='currentColor'
    />
    <path
      id='Path_account-tie-outline-2'
      data-name='Path / account-tie-outline'
      d='M12,5a2,2,0,1,0,2,2,2.006,2.006,0,0,0-2-2m0,6a4,4,0,1,1,4-4A4,4,0,0,1,12,11Z'
      fill='currentColor'
    />
  </svg>,
  'Admin'
);

export default Admin;
