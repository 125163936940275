import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { curd } from 'api/curd';
import { useNavigate } from 'react-router-dom';

interface RedirectToRegister {
  children: ReactNode;
}

const RedirectToRegister: FC<RedirectToRegister> = (props) => {
  const navigate = useNavigate();
  const { children } = props;

  useEffect(() => {
    curd.getAll({ url: 'super-dashboard/auth/is-there-super-admin' }).then((res) => {
      if (!res.data.data.result) {
        navigate('/register', { replace: true });
      }
    });
  }, []);

  return <>{children}</>;
};

RedirectToRegister.propTypes = {
  children: PropTypes.node,
};

export default RedirectToRegister;
