import { createSvgIcon } from '@mui/material';

const HTML = createSvgIcon(
  <svg
    fill='#FFF'
    version='1.1'
    id='Capa_1'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 550.801 550.801'
  >
    <g>
      <path
        d='M488.426,197.019H475.2v-63.816c0-0.401-0.063-0.799-0.116-1.205c-0.021-2.534-0.827-5.023-2.562-6.992L366.325,3.691
		c-0.032-0.031-0.063-0.042-0.085-0.073c-0.633-0.707-1.371-1.298-2.151-1.804c-0.231-0.158-0.464-0.287-0.706-0.422
		c-0.676-0.366-1.393-0.675-2.131-0.896c-0.2-0.053-0.38-0.135-0.58-0.19C359.87,0.119,359.037,0,358.193,0H97.2
		c-11.918,0-21.6,9.693-21.6,21.601v175.413H62.377c-17.049,0-30.873,13.818-30.873,30.87v160.542
		c0,17.044,13.824,30.876,30.873,30.876h13.224V529.2c0,11.907,9.682,21.601,21.6,21.601h356.4c11.907,0,21.6-9.693,21.6-21.601
		V419.302h13.226c17.044,0,30.871-13.827,30.871-30.87V227.89C519.297,210.838,505.47,197.019,488.426,197.019z M97.2,21.605
		h250.193v110.51c0,5.967,4.841,10.8,10.8,10.8h95.407v54.108H97.2V21.605z M406.467,363.983h-26.104l-1.751-46.464
		c-0.527-14.598-1.055-32.236-1.055-49.895h-0.517c-3.691,15.485-8.596,32.782-13.163,47.015l-14.354,47.366h-20.851l-12.625-47.002
		c-3.85-14.228-7.889-31.524-10.694-47.374h-0.348c-0.707,16.397-1.234,35.129-2.1,50.253l-2.114,46.111h-24.711l7.535-121.395
		h35.575l11.569,40.521c3.691,14.048,7.362,29.183,9.999,43.416h0.521c3.344-14.054,7.356-30.074,11.222-43.585l12.614-40.353
		h34.879L406.467,363.983z M174.438,265.642v-23.058h90.77v23.058h-32.239v98.342h-26.821v-98.342H174.438z M89.403,313.195v50.788
		H62.604V242.584h26.799v46.654h43.999v-46.654h26.645v121.399h-26.645v-50.794H89.403V313.195z M453.601,523.353H97.2V419.302
		h356.4V523.353z M500.787,363.983h-73.786V242.584h26.811v98.339h46.976V363.983z'
      />
    </g>
  </svg>,
  'HTML'
);

export default HTML;
