import { AdminResponse } from 'dto/admin';
import { TicketSupportType } from './ticket-support-type';
import { convertSecondesToDayHHMM } from 'helpers/moment.helper';

export enum TicketAssignedStatuses {
  ASSIGNED = 'assigned',
  UN_ASSIGNED = 'un-assigned',
}

export enum TicketStatuses {
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  CLOSED = 'closed',
  REOPEN = 'reopen',
}

export enum TicketReplyStatuses {
  REPLIED = 'replied',
  WAITING_REPLY = 'waiting_reply',
}
export interface Ticket {
  id: string;
  ticket_number: string;
  status: string;
  priority: string;
  user: any;
  admin: AdminResponse;
  support_type: TicketSupportType;
  assigned_status: TicketAssignedStatuses;
  is_new: boolean;
  can_reply: boolean;
  can_change_status: boolean;
  reply_status: TicketReplyStatuses;
  created_at: string;
}

export interface TicketLogResponse {
  create_ticket: AdminResponse | null;
  in_progress_ticket: AdminResponse | null;
  close_ticket: AdminResponse | null;
}

export const makeTicketAgeLabel = (ticket_age_in_seconds: number) => {
  if (ticket_age_in_seconds < 60) return 'Just now';
  return convertSecondesToDayHHMM(ticket_age_in_seconds);
};
