import { createSvgIcon } from '@mui/material/utils';

const Look = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' id='lock' width='20' height='20' viewBox='0 0 20 20'>
    <path id='Path_2470' data-name='Path 2470' d='M0,0H20V20H0Z' fill='none' />
    <path
      id='Path_2471'
      data-name='Path 2471'
      d='M14.667,6.667h1.556a.794.794,0,0,1,.778.81V17.19a.794.794,0,0,1-.778.81H3.778A.794.794,0,0,1,3,17.19V7.476a.794.794,0,0,1,.778-.81H5.333v-.81A4.765,4.765,0,0,1,10,1a4.765,4.765,0,0,1,4.667,4.857ZM9.222,12.926v1.836h1.556V12.926A1.647,1.647,0,0,0,11.5,11.1a1.541,1.541,0,0,0-3.005,0,1.647,1.647,0,0,0,.725,1.821Zm3.889-6.259v-.81A3.177,3.177,0,0,0,10,2.619,3.177,3.177,0,0,0,6.889,5.857v.81Z'
      transform='translate(0)'
      fill='#ffb74d'
    />
  </svg>,
  'Look'
);

export default Look;
