import { createSvgIcon } from '@mui/material/utils';

const SuperAdmin = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
    <path
      fill='currentColor'
      d='M139.625 23.563c-1.58.008-3.147.052-4.72.125l171.75 258.093h146.25c15.35-16.906 27.875-35.263 35.69-56.56h-96.876c-7.317 18.17-25.136 31.093-45.845 31.093c-27.173 0-49.375-22.233-49.375-49.407c0-11.436 3.95-21.978 10.53-30.375l-49.155-77.655c-.03.053-.063.103-.094.156a195.36 195.36 0 0 0-3.624-6c-30.106-48.086-73.28-69.694-114.53-69.468zm228.656 2.656c-35.864.328-72.648 18.056-100.78 57.655h204.125C447.147 47.133 408.293 25.85 368.28 26.22zm-253.967.25c-11.495 2.624-22.58 7.007-32.876 13c10.672 9.08 17.47 22.6 17.47 37.624c0 12.72-4.873 24.35-12.844 33.125l43.187 67.31h85.594zM49.5 46.374c-17.074 0-30.72 13.645-30.72 30.72c0 17.073 13.646 30.686 30.72 30.686c17.074 0 30.72-13.612 30.72-30.686c0-17.074-13.646-30.72-30.72-30.72m232.844 56.188l38.97 61.53a48.966 48.966 0 0 1 24.56-6.593c27.05 0 49.203 22.03 49.407 49.03h98.75c2.575-11.807 3.757-24.49 3.345-38.25c-.735-24.555-6.237-46.66-15.22-65.718h-199.81zm-259.47 16.062c-4.02 15.02-5.985 31.62-5.436 49.656c.095 3.143.293 6.215.562 9.25h89.063l-35.97-56.06a48.996 48.996 0 0 1-21.593 5c-9.79 0-18.926-2.887-26.625-7.845zm323 57.563c-17.073 0-30.687 13.644-30.687 30.718c0 17.074 13.614 30.72 30.688 30.72c17.074 0 30.72-13.646 30.72-30.72c0-17.074-13.646-30.72-30.72-30.72zm-325.03 20.03c3.693 16.938 10.187 32.203 18.75 46.345h156.968l2.75 4.343L276.97 369.53l9.092 14.345H212.22a49.277 49.277 0 0 1-8.282 19c22.164 24.562 41.335 52.573 53.843 86.75c35.156-90.944 118.132-134.872 176.564-189.156h-137.72l-2.78-4.19l-66.594-100.06H20.844zM52.5 261.25c21.193 27.23 49.796 50.764 79.313 75.313c8.633-7.354 19.808-11.813 31.968-11.813c24.116 0 44.348 17.504 48.595 40.438h39.72L186.28 261.25zm111.28 82.188c-17.073 0-30.718 13.644-30.718 30.718c0 17.074 13.645 30.72 30.72 30.72c17.073 0 30.687-13.646 30.687-30.72c0-17.074-13.615-30.72-30.69-30.72z'
    />
  </svg>,
  'TechAdmin'
);

export default SuperAdmin;
