const LOCAL_STORAGE_KEYS = {
  JWT: 'JWT',
};

export const getJWT = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.JWT);
};

export const setJWT = (JWT: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.JWT, JWT);
};

export const removeJWT = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.JWT);
};
